/* eslint-disable import/prefer-default-export */
import { SyntheticEvent } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import moment, { Moment } from 'moment/moment';
import { userIsHbs } from './components/findUserType';
import {
    customPeriodName,
    customSensorGraphPeriods,
    dateFormats,
    DeviceModel,
    deviceTypes,
    englishFallbackLanguageName,
    graphResolutions,
    languages,
    norwegianFallbackLanguageName,
    publicDashboardUrl,
    publicDeviceUrl,
    sensorGraphColors,
    sensorGraphPeriods,
    colors,
} from './constants';
import { viewDevices } from './DeviceAndSensorLists';
import i18n from './i18n';
import {
    DeviceTypeNames,
    EnumStatusColors,
    PillStatus,
    Rating,
    Resolution,
    SensorTypes,
    SubscriptionDeviceTypeNames,
    SubscriptionType,
    VirtualDeviceType,
} from './models/commonEnums';
import {
    AnyDeviceType,
    CurrentSensorValuesType,
    DeviceWithKeyInfo,
    SelectedPeriod,
    SensorSampleInterval,
    SubscriptionPlan,
    ThresholdRange,
    Units,
} from './models/commonTypeScript';

dayjs.extend(utc);
dayjs.extend(relativeTime);

export const isVirtualDevice = (deviceType?: AnyDeviceType): boolean => {
    return !!deviceType && Object.keys(VirtualDeviceType).includes(deviceType);
};

export const isPhysicalDevice = (deviceType?: AnyDeviceType): boolean =>
    !!deviceType && Object.values(DeviceTypeNames).includes(deviceType as DeviceTypeNames);

// TODO remove this when updated to use new units on third party integrations
export const setMeasurementSystem = (units: Units): string => (units && units.tempUnit === 'f' ? 'US' : 'METRIC');

export const getDotColor = (thresholds: number[], sensorType: string, value?: number): EnumStatusColors => {
    if (thresholds.length === 0) return EnumStatusColors.green;
    if (value === undefined) return EnumStatusColors.grey;

    const sortedThresholds = [...thresholds].sort((a, b) => a - b);
    const colorIndex = sortedThresholds.findIndex(threshold => threshold > value);
    const colorCode = sensorGraphColors[`${colorIndex}${sensorType}` as keyof typeof sensorGraphColors];

    if (colorIndex < 0) return EnumStatusColors.red;
    if (colorCode === colors.graphGreen) return EnumStatusColors.green;
    if (colorCode === colors.graphYellow) return EnumStatusColors.yellow;

    return EnumStatusColors.red;
};

export const getSensorPillStatus = (
    thresholds: number[],
    sensorType: string,
    value?: number
): { status: PillStatus; icon?: string } => {
    const dotColor = getDotColor(thresholds, sensorType, value);
    if (dotColor === EnumStatusColors.red) return { status: PillStatus.alert, icon: 'emergency_home' };
    if (dotColor === EnumStatusColors.yellow) return { status: PillStatus.warning, icon: 'report' };
    if (dotColor === EnumStatusColors.green) return { status: PillStatus.success };
    return { status: PillStatus.neutral };
};

export const maxTileSensors = 6;
export const sortSensors = <T extends { type: string }>(
    sensors: T[] = [],
    order: string[] = [],
    maxSensors: number | undefined = undefined
): T[] =>
    sensors
        .filter(sensor => order.includes(sensor.type))
        .sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type))
        .slice(0, maxSensors);

export const deviceStatusColor = (
    airRatingSensors: string[],
    isHubConnectionLost: boolean,
    currentSensorValues: CurrentSensorValuesType[] = []
): EnumStatusColors => {
    if (isHubConnectionLost) {
        return EnumStatusColors.grey;
    }
    return currentSensorValues.reduce((statusColor: EnumStatusColors, sensor: CurrentSensorValuesType) => {
        if ((airRatingSensors || []).includes(sensor.type as SensorTypes)) {
            const dotColor = getDotColor(sensor.thresholds, sensor.type, sensor.value);
            if (
                dotColor === EnumStatusColors.grey ||
                statusColor === EnumStatusColors.red ||
                (statusColor === EnumStatusColors.yellow && dotColor === EnumStatusColors.green)
            )
                return statusColor;
            return dotColor;
        }
        return statusColor;
    }, EnumStatusColors.grey);
};

export const statusColorForHttpResponse = (statusCode?: number): EnumStatusColors =>
    statusCode && Math.floor(statusCode / 100) === 2 ? EnumStatusColors.green : EnumStatusColors.red;

export const localhostEnvironmentPrefix = 'dev';
export const environmentPrefix = (hostname: string): string | undefined => {
    if (hostname === 'localhost') {
        return localhostEnvironmentPrefix;
    }
    const match = (hostname || '').match(/(dashboard|now)(.*?).airthin\.?gs/);
    return match && match.length > 2 ? match[2].substr(1) : undefined;
};

export const isProd = !environmentPrefix(window.location.hostname);

export const getDeviceTypeFromPrefix = (inputPrefix: string): string | undefined => {
    // DO NOT USE THIS UNLESS YOU HAVE TO. Use deviceType from the device instead.
    const matchingDevice = Object.keys(deviceTypes)
        .map(key => deviceTypes[key])
        .find(device => device.isValidPrefix(inputPrefix));
    return matchingDevice && matchingDevice.type;
};

export const isFallBackLanguage = (language: string): boolean => {
    const initialPartOfLanguageString = language.split('-')[0] || '';
    return !Object.keys(languages).includes(initialPartOfLanguageString.toLowerCase());
};

export const getLanguageNameString = (languageTag: string): string => {
    const selectedLanguageString = languages[languageTag];
    if (selectedLanguageString) return selectedLanguageString;
    if (['no', 'nn'].includes(languageTag)) {
        return norwegianFallbackLanguageName;
    }
    return englishFallbackLanguageName;
};

export const getValidLanguageTagForMoment = (): string => {
    return i18n.t('MomentLanguage');
};

export const isTestDevice = (serialNumber: string): boolean => {
    const testDeviceRegex = /2[0-9]{2}T[0-9a-zA-Z]{6}/;
    if (isProd) return false;
    return testDeviceRegex.test(serialNumber);
};

export const batteryIntervals = (
    sensorIntervals: SensorSampleInterval[] | undefined
): { [sensor: string]: number } | null =>
    sensorIntervals && sensorIntervals.length > 0
        ? sensorIntervals.reduce(
              (intervalObject, sensorSettings) => ({
                  ...intervalObject,
                  [sensorSettings.sensorType]: sensorSettings.current,
              }),
              {}
          )
        : null;

export const getQueryParams = (
    searchQuery: string
): {
    code?: string;
    state?: string;
    token?: string;
    hbs?: string;
    inviteId?: string;
    tokenSignature?: string;
    // eslint-disable-next-line camelcase
    product_tour_id?: string;
    sso?: string;
} => {
    const hashes = searchQuery.slice(searchQuery.indexOf('?') + 1).split('&');
    if (hashes.length > 1 || !hashes.includes('')) {
        return hashes.reduce((redirectInfo, hash) => {
            const attr = hash.split('=');
            return { ...redirectInfo, [attr[0]]: attr[1] };
        }, {});
    }
    return {};
};

export const publicQrUrl = (url: string, language: string, units: Units): string => {
    const measurementSystem = units.lengthUnit === 'ft' ? 'US' : 'METRIC';
    return publicDeviceUrl
        .replace('{{publicUrlPath}}', url)
        .replace('{{languageCode}}', language)
        .replace('{{measurementSystem}}', measurementSystem);
};

export const urlPublicDashboard = (url: string, language: string, units: Units): string => {
    const measurementSystem = units.lengthUnit === 'ft' ? 'US' : 'METRIC';
    return publicDashboardUrl
        .replace('{{publicUrlPath}}', url)
        .replace('{{languageCode}}', language)
        .replace('{{measurementSystem}}', measurementSystem);
};

export const deviceCount = (
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo },
    hubsWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo }
): { [deviceType: string]: number } => {
    const devicesOfType = Object.keys(deviceTypes)
        .map(deviceOfType => {
            if (deviceOfType === DeviceTypeNames.hub) {
                return { deviceType: DeviceTypeNames.hub, count: Object.keys(hubsWithKeyInfo).length };
            }
            const numberOfDevices = Object.values(devicesWithKeyInfo).filter(
                device => device.type === deviceTypes[deviceOfType].type
            ).length;
            return { deviceType: deviceTypes[deviceOfType].type as DeviceTypeNames, count: numberOfDevices };
        })
        .filter(deviceWithCount => isPhysicalDevice(deviceWithCount.deviceType) && deviceWithCount.count > 0);
    const deployedDevices: { [deviceType: string]: number } = {};
    devicesOfType.forEach(deviceCounter => {
        deployedDevices[deviceCounter.deviceType] = deviceCounter.count;
    });
    return deployedDevices;
};

export const copyToClipboard = (text: string): void => {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

export const handleInvalidNumberValue = (value?: string): number | undefined =>
    value === undefined || value === '0' ? undefined : parseFloat(value);

export const deviceIsView = (deviceType: DeviceTypeNames): boolean => {
    return viewDevices.includes(deviceType);
};

export const hubConnectionIsUp = (lastConnection?: string): boolean =>
    !!lastConnection && dayjs.utc().diff(dayjs.utc(lastConnection), 'minutes') < 65;
export const lastSyncedDate = (lastConnection: string, dateFormat: keyof typeof dateFormats): string[] => [
    dayjs.utc(lastConnection).local().format(dateFormats[dateFormat].shortFormat),
    ' ',
    dayjs.utc(lastConnection).local().format(dateFormats[dateFormat].hourFormat),
];

export const lastSyncedTime = (timeStamp: string, dateFormat: keyof typeof dateFormats): string | string[] => {
    // const language = i18n.t('MomentLanguage');
    if (!timeStamp) return i18n.t('neverSynced');
    const deviceRecentlySynced = dayjs.utc().diff(dayjs.utc(timeStamp).local(), 'hours') < 1;

    return deviceRecentlySynced
        ? dayjs.utc(timeStamp).local().locale('en').fromNow()
        : lastSyncedDate(timeStamp, dateFormat);
};

export const parseToInteger = (e: SyntheticEvent<HTMLInputElement>): number => {
    const number = parseInt(e.currentTarget.value.trim().replace(/[^0-9]+/g, ''), 10);
    const newNumber = Number.isNaN(number) ? 0 : number;
    return newNumber;
};

/**
 * For business groups, maps specific device type names over to new names.
 * @param deviceType
 */
export const mapDeviceType = <T extends AnyDeviceType>(deviceType: T): AnyDeviceType => {
    const mapToBusinessType = (): AnyDeviceType => {
        switch (deviceType) {
            case DeviceTypeNames.hub:
                return DeviceTypeNames.spaceHub;
            case DeviceTypeNames.wavePlus:
                return DeviceTypeNames.spacePlus;
            case DeviceTypeNames.waveNano:
                return DeviceTypeNames.spaceNano;
            default:
                return deviceType;
        }
    };
    return userIsHbs() ? mapToBusinessType() : deviceType;
};

export const isConsumerAppPage = (): boolean => {
    const pathName: string = window.location.pathname;
    return pathName.includes('/app-devices');
};

/**
 * Common functions related to Zuora subscriptions
 */

export const getKeyByValue = (value: string): string => {
    const indexOfSubscriptionDeviceTypeNames = Object.values(SubscriptionDeviceTypeNames).indexOf(
        value as SubscriptionDeviceTypeNames
    );
    return Object.keys(SubscriptionDeviceTypeNames)[indexOfSubscriptionDeviceTypeNames];
};

export const limitSubscription = (subscriptionPlan?: SubscriptionPlan): boolean => {
    const limitedSubscriptionDateIsPast =
        (subscriptionPlan?.limitingDate && dayjs(subscriptionPlan.limitingDate).isBefore(dayjs())) || false;
    const limitedSubscription = subscriptionPlan?.subscriptionType === SubscriptionType.LIMITED || false;
    return limitedSubscription && limitedSubscriptionDateIsPast;
};

export const limitWithinAWeek = (subscriptionPlan?: SubscriptionPlan): boolean => {
    return (
        (subscriptionPlan?.limitingDate && dayjs(subscriptionPlan.limitingDate).isBefore(dayjs().add(7, 'days'))) ||
        false
    );
};

/* -----Sensor ordering ---- */
export const listSensorOrder: { [key in DeviceModel]: SensorTypes[] } = Object.keys(deviceTypes)
    .map(deviceType => {
        const sensors = (deviceTypes[deviceType] && deviceTypes[deviceType].sensors) || [];
        const indexOfRadonAvg = sensors.indexOf(SensorTypes.radonShortTermAvg);
        if (userIsHbs() && indexOfRadonAvg > -1) {
            // if AfB replace radonShortTermAvg with hourlyRadon
            sensors[indexOfRadonAvg] = SensorTypes.hourlyRadon;
        }
        return {
            deviceType,
            sensors: (deviceTypes[deviceType] && deviceTypes[deviceType].sensors) || [],
        };
    })
    .reduce((a, b) => {
        return { ...a, [b.deviceType]: b.sensors };
    }, {});
export const sensorsTileSort = (sensors: string[] | undefined): SensorTypes[] => {
    if (sensors === undefined) return [];
    const itemsPerRow = sensors.length > 4 ? 3 : 2;
    return sensors
        .map(sensor => {
            const index = sensors.indexOf(sensor);
            return {
                index: index / itemsPerRow + (index % itemsPerRow),
                sensor,
            };
        })
        .sort((sensorA, sensorB) => sensorA.index - sensorB.index)
        .map(item => item.sensor as SensorTypes);
};
export const tileSensorOrder: { [key in DeviceModel]: SensorTypes[] } = Object.keys(deviceTypes)
    .map(deviceType => {
        const sensors = sensorsTileSort(deviceTypes[deviceType].sensors && deviceTypes[deviceType].sensors.slice(0, 6));
        const indexOfRadonAvg = sensors.indexOf(SensorTypes.radonShortTermAvg);
        if (userIsHbs() && indexOfRadonAvg > -1) {
            // if AfB replace radonShortTermAvg with hourlyRadon
            sensors[indexOfRadonAvg] = SensorTypes.hourlyRadon;
        }
        return {
            deviceType,
            sensors,
        };
    })
    .reduce((a, b) => ({ ...a, [b.deviceType]: b.sensors }), {});
export const sensorRating = (ratings: ThresholdRange[], value: number | undefined): undefined | Rating => {
    if (value === undefined) return undefined;
    const valueInRange = ratings.find(rating => {
        const lessThanTo = rating.to ? rating.to > value : true;
        const moreThanFrom = rating.from ? rating.from <= value : true;
        return lessThanTo && moreThanFrom;
    });
    return valueInRange && valueInRange.rating;
};
export const statusColor = (ranges: ThresholdRange[], value: number | undefined): EnumStatusColors => {
    const valueRange: Rating | undefined = sensorRating(ranges, value);
    let color = EnumStatusColors.grey;
    if (valueRange === Rating.POOR) color = EnumStatusColors.red;
    else if (valueRange === Rating.FAIR) color = EnumStatusColors.yellow;
    else if (valueRange === Rating.GOOD) color = EnumStatusColors.green;
    return color;
};
export const setCustomPeriod = (newStartDate: Moment, newEndDate: Moment, timeZone?: string): SelectedPeriod => {
    const { twoDays, week, month, year } = sensorGraphPeriods;
    let label = twoDays.name;
    let resolutionType = 'full';
    const { threeDaysResolution, dayResolution, fourHoursResolution, hourResolution } = customSensorGraphPeriods;
    if (newStartDate && newEndDate) {
        const periodDuration = moment.duration(newEndDate.diff(newStartDate)).asSeconds();

        if (periodDuration > threeDaysResolution) {
            resolutionType = Resolution.threeDays;
            label = year.name;
        } else if (periodDuration > dayResolution) {
            resolutionType = Resolution.day;
            label = month.name;
        } else if (periodDuration > fourHoursResolution) {
            resolutionType = Resolution.fourHours;
            label = month.name;
        } else if (periodDuration > hourResolution) {
            resolutionType = Resolution.hour;
            label = week.name;
        }
    }
    const startDateInTimeZone = (timeZone ? moment.utc(newStartDate).tz(timeZone) : moment(newStartDate)).startOf(
        'day'
    );
    const endDateInTimeZone = (timeZone ? moment.utc(newEndDate).tz(timeZone) : moment(newEndDate)).endOf('day');
    return {
        name: customPeriodName,
        startDate: startDateInTimeZone,
        endDate: endDateInTimeZone,
        label,
        ...graphResolutions[resolutionType],
    };
};

export const getDashboardPath = (): string => {
    const isBusiness = userIsHbs();
    if (isBusiness) return '/dashboard';
    return '/';
};
