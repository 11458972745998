import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../components/buttons/PrimaryButton';
import MaterialIcon from '../components/MaterialIcon';
import styles from './Accordion.module.scss';

type Props = {
    isOpenByDefault?: boolean;
    header: string;
    subHeader?: string | React.ReactElement;
    children: React.ReactNode;
    translateHeader?: boolean;
    translateSubHeader?: boolean;
    testAttr?: string;
    loading?: boolean;
    onToggle?: (isOpen: boolean) => void;
};

const Accordion: React.FC<Props> = ({
    isOpenByDefault = false,
    header,
    subHeader,
    children,
    loading = false,
    testAttr = '',
    translateHeader = false,
    translateSubHeader = false,
    onToggle,
}) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(isOpenByDefault);

    const handleToggle = (): void => {
        const newState = !isOpen;
        setIsOpen(newState);
        onToggle?.(newState);
    };

    const renderSubHeader = (): React.ReactElement | null => {
        if (!subHeader) return null;
        return React.isValidElement(subHeader) ? (
            subHeader
        ) : (
            <div className="font-medium">{translateSubHeader ? t(subHeader as string) : subHeader}</div>
        );
    };

    return (
        <div className={styles.modalContainer}>
            <div className={styles.modalHeader}>
                <h3>{translateHeader ? t(header) : header}</h3>
                {renderSubHeader()}
                <PrimaryButton
                    onClick={handleToggle}
                    testAttr={`toggle-collapsible-accordion-${testAttr}`}
                    icon={<MaterialIcon name={isOpen ? 'expand_less' : 'expand_more'} />}
                    color="tertiary"
                    loading={loading}
                />
            </div>
            {isOpen && <div className={styles.modalContent}>{children}</div>}
        </div>
    );
};

export default Accordion;
