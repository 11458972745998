import { isVirtualDevice } from 'commons/src/commonFunctions';
import { deviceTypes } from 'commons/src/constants';
import { wantedInInletAir } from 'commons/src/DeviceAndSensorLists';
import { SensorTypes, VirtualDeviceType } from 'commons/src/models/commonEnums';
import { AnyDeviceType } from 'commons/src/models/commonTypeScript';

const isDeviceAllowedInGroup = (device: { type?: AnyDeviceType }, groupType?: VirtualDeviceType): boolean => {
    switch (groupType) {
        case VirtualDeviceType.inletAirControl:
            return (
                (deviceTypes[device.type as keyof typeof deviceTypes].sensors || []).some(sensorType =>
                    wantedInInletAir.includes(sensorType as SensorTypes)
                ) && !isVirtualDevice(device.type)
            );
        default:
            return !isVirtualDevice(device.type);
    }
};

export default isDeviceAllowedInGroup;
