import React, { ReactElement } from 'react';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import styles from './DotNavigation.module.scss';

export type DotNavigationProps<T> = {
    items: T[];
    activeIndex: number;
    setActiveIndex: (index: number) => void;
    getKey: (item: T) => string;
};

const DotNavigation = <T,>({ items, activeIndex, setActiveIndex, getKey }: DotNavigationProps<T>): ReactElement => (
    <div className={styles.navigation}>
        {items.length > 1 &&
            items.map((item, index) => (
                <TertiaryButton
                    key={getKey(item)}
                    onClick={(): void => setActiveIndex(index)}
                    trailingIcon={
                        <div className={index === activeIndex ? styles.navIconActive : styles.navIconInactive} />
                    }
                />
            ))}
    </div>
);

export default DotNavigation;
