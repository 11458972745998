import { sensorUnits } from 'commons/src/constants';
import { DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import {
    FETCH_RADON_INSIGHT_DATA_SUCCESS,
    SET_INSIGHT_SELECTED_DEVICES,
    AllInsightActions,
    InsightActionType,
} from '../actions/insightActions';
import { RadonInsightSensorData } from '../models/common';

export type RadonInsightState = {
    locationId: string | undefined;
    reports: RadonInsightSensorData[];
    errors: { error: string; serialNumber: string }[];
    reportId: string;
    unit: keyof typeof sensorUnits;
    thresholds: number[];
    selectedDevices: DeviceWithKeyInfo[];
    includeLogo: boolean;
};

const initialState: RadonInsightState = {
    locationId: undefined,
    thresholds: [],
    reports: [],
    errors: [],
    reportId: '',
    unit: 'none',
    selectedDevices: [],
    includeLogo: false,
};

const { FetchRadonInsightDataInit } = InsightActionType;

export default (state: RadonInsightState = initialState, action: AllInsightActions): RadonInsightState => {
    switch (action.type) {
        case FetchRadonInsightDataInit:
            return {
                ...state,
            };
        case FETCH_RADON_INSIGHT_DATA_SUCCESS:
            return {
                ...state,
                locationId: action.payload.locationId,
                reports: action.payload.reports,
                reportId: action.payload.radonReportId,
                unit: action.payload.radonUnit,
                thresholds: action.payload.thresholds,
                errors: action.payload.errors,
                includeLogo: action.payload.includeLogo,
            };
        case SET_INSIGHT_SELECTED_DEVICES:
            return {
                ...state,
                selectedDevices: action.devices,
            };
        default:
            return state;
    }
};
