import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ReactPdfDownloadModal from 'commons/src/components/PDF/ReactPdfDownloadModal';
import { radonInsightPlaceholder } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { dateFormats } from 'commons/src/constants';
import { virusRiskFooterDevices } from 'commons/src/DeviceAndSensorLists';
import { Rating } from 'commons/src/models/commonEnums';
import {
    BuildingType,
    DeviceWithKeyInfo,
    SegmentProperties,
    SensorThresholds,
} from 'commons/src/models/commonTypeScript';
import { VirusRiskInsightData } from '../../../models/common';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import { generateImage } from '../GraphConfig';
import FooterSection from '../iaqInsight/FooterSection';
import { createObserverAndFetchSvg } from '../insightFunctions';
import GeneratePdf from '../insightPdfs/virusRiskPdf/GenerateVirusRiskPdf';
import DeviceResultMissingData from '../radonInsight/DeviceResultMissingData';
import VirusDeviceResult from './VirusDeviceResult';
import deviceLevel from './VirusFunctions';
import VirusResultSummary from './VirusResultSummary';

type ParentProps = {
    from: Moment | null;
    to: Moment | null;
    setInfoModalOpen: (props: { open: boolean; selectedTab: number }) => void;
    optionalBuildingProps: string[];
};

type StateProps = {
    dateFormat: keyof typeof dateFormats;
    devices: { [serialNumber: string]: DeviceWithKeyInfo };
    loading: boolean;
    locationId: string | undefined;
    segmentProperties: { [serialNumber: string]: SegmentProperties };
    thresholds: { [sensor: string]: SensorThresholds };
    deviceReports: VirusRiskInsightData[];
    errors: { error: string; serialNumber: string }[];
    possibleRiskFactors: { riskName: string; sensorName: string }[];
    reportId: string;
    userName: string;
    includeLogo: boolean;
    logoImage?: string;
    buildings: { [buildingId: string]: BuildingType };
};

type Props = ParentProps & StateProps;

export const VirusRiskResultView = (props: Props): React.ReactElement => {
    const {
        from,
        to,
        devices,
        locationId,
        dateFormat,
        buildings,
        loading,
        deviceReports,
        errors,
        thresholds,
        setInfoModalOpen,
        segmentProperties,
        reportId,
        possibleRiskFactors,
        userName,
        includeLogo,
        logoImage,
        optionalBuildingProps,
    } = props;
    const { t: txt } = useTranslation();
    const [listOfImages, setListOfImages] = useState<{ data: string; serialNumber: string }[]>([]);
    const [generateChartError, setChartError] = useState(false);
    const [downloadModalOpen, setDownloadModalOpen] = useState(false);
    const [comment, setComment] = useState('');

    const downloadButtonEnabled = listOfImages.length === deviceReports.length;

    const getSvg = async (): Promise<void> => {
        const images: { data: string; serialNumber: string }[] = [];
        const updateListOfImages = (response: { data: string; serialNumber: string }): void => {
            images.push(response);
        };
        await deviceReports.forEach(report => {
            generateImage(updateListOfImages, setChartError, report.serialNumber);
        });
        await setListOfImages(images);
    };

    useEffect(() => {
        if (!loading) {
            const element = document.getElementById('resultHeader');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
                const observer = createObserverAndFetchSvg('virusResultSummary', getSvg);
                observer.observe(document, {
                    childList: true,
                    subtree: true,
                });
            }
        }
    }, [loading]);

    const building = locationId ? buildings[locationId] : undefined;
    const usageHours = building ? building.usageHours : {};
    const fromDate = from && moment(from).format(dateFormats[dateFormat].shortFormat);
    const toDate = to && moment(to).format(dateFormats[dateFormat].shortFormat);
    const timeZone = building && building.timezone ? building.timezone : 'UTC';

    const devicesInEachLevel = deviceReports.reduce(
        (devicesInLevels, report) => {
            const deviceRating = deviceLevel(report.averageValueWithinOpeningHours, thresholds.virusRisk.ranges);
            const updatedLevel = devicesInLevels[deviceRating] + 1;
            return { ...devicesInLevels, [deviceRating]: updatedLevel };
        },
        { [Rating.GOOD]: 0, [Rating.FAIR]: 0, [Rating.POOR]: 0 }
    );

    const sortedReports = deviceReports.sort((reportA, reportB) => {
        const reportASerialNumber = reportA.serialNumber;
        const reportBSerialNumber = reportB.serialNumber;
        const reportAName = devices[reportASerialNumber]?.segmentName || '';
        const reportBName = devices[reportBSerialNumber]?.segmentName || '';
        if (!reportASerialNumber || !reportBSerialNumber || !reportAName || !reportBName) {
            Sentry.captureEvent({
                message: 'Virus risk not able to access roomName for device',
                extra: { reportASerialNumber, reportBSerialNumber, reportAName, reportBName },
                level: 'warning',
            });
        }
        return reportAName.localeCompare(reportBName);
    });

    const pdfData = {
        fromDate,
        toDate,
        dateFormat,
        devicesInEachLevel,
        building: building || undefined,
        deviceReports: sortedReports,
        thresholds,
        devices,
        segmentProperties,
        logo: includeLogo ? logoImage : undefined,
        comment,
        listOfImages,
        possibleRiskFactors,
        reportDetails: {
            userName,
            reportId,
            deviceTypes: ['wavePlus'],
        },
    };

    return (
        <div id="virusInsightReport" className="page-wrapper-flex page-wrapper-flex--content-margin">
            {downloadModalOpen && (
                <ReactPdfDownloadModal title="Download" onClose={(): void => setDownloadModalOpen(false)}>
                    <GeneratePdf {...pdfData} />
                </ReactPdfDownloadModal>
            )}
            <div id="resultHeader" className="inline-header-lined">
                <h2 className="inline-header-lined__text">
                    {!loading && fromDate && toDate
                        ? txt('RadonInsight.ReportGeneratedPeriod', { from: fromDate, to: toDate })
                        : txt('Loading')}
                </h2>
            </div>
            <ReactPlaceholder ready={!loading} customPlaceholder={radonInsightPlaceholder}>
                {building && (
                    <VirusResultSummary
                        devicesInEachLevel={devicesInEachLevel}
                        building={building}
                        downloadButtonEnabled={downloadButtonEnabled}
                        setDownloadModalOpen={setDownloadModalOpen}
                        optionalBuildingProps={optionalBuildingProps}
                    />
                )}
                {sortedReports.map(deviceResult => (
                    <VirusDeviceResult
                        from={from}
                        to={to}
                        key={deviceResult.serialNumber}
                        deviceResult={deviceResult}
                        usageHours={usageHours}
                        setInfoModalOpen={setInfoModalOpen}
                        timeZone={timeZone}
                    />
                ))}
                {errors.map(errorDevice => (
                    <DeviceResultMissingData
                        key={errorDevice.serialNumber}
                        serialNumber={errorDevice.serialNumber}
                        error={errorDevice.error}
                    />
                ))}
                {generateChartError && (
                    <div className="form form__wide-container">
                        <ResponseBox text="SomethingWentWrong" subtext={txt('RadonInsight.GeneratePdfError')} />
                    </div>
                )}
                <FooterSection
                    comment={comment}
                    updateComment={setComment}
                    reportId={reportId}
                    deviceTypes={virusRiskFooterDevices}
                    disclaimer="VirusInsight.ReportDisclaimer"
                />
                <div className="form__row form__button-container">
                    <PrimaryButton
                        onClick={(): void => setDownloadModalOpen(true)}
                        disabled={!downloadButtonEnabled}
                        title="Download"
                        color="primary"
                    />
                </div>
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat, userName },
        devices: { devicesWithKeyInfo: devices },
        requests: {
            [RequestType.FetchVirusRiskInsightData]: { loading },
        },
        segmentPropertiesStore: { segmentProperties },
        organizationProperties: { logoImage },
        buildings: { buildings },
        virusRiskInsight: { locationId, thresholds, deviceReports, errors, reportId, possibleRiskFactors, includeLogo },
    } = state;

    return {
        dateFormat,
        devices,
        loading,
        segmentProperties,
        locationId,
        thresholds,
        deviceReports,
        errors,
        userName,
        possibleRiskFactors,
        reportId,
        includeLogo,
        logoImage,
        buildings,
    };
};

export default connect(mapStateToProps)(VirusRiskResultView);
