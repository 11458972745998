import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import SimpleTreeView from 'commons/src/components/lists/TreeView/SimpleTreeView';
import { LocationType } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';

export type ParentProps = {
    setSelectedLocations: (locations: string[]) => void;
    selectedLocations: string[];
    subscribedToAll?: boolean;
    setSubscribedToAll?: (subscribedToAll: boolean) => void;
};

type StateProps = {
    locations: LocationType[];
    loading: boolean;
};

export type Props = StateProps & ParentProps;

export const LocationsSelectorComponent = ({
    locations,
    setSelectedLocations,
    selectedLocations,
    loading,
    subscribedToAll,
    setSubscribedToAll,
}: Props): React.ReactElement => {
    useEffect(() => {
        if (subscribedToAll) {
            setSelectedLocations(locations.map(loc => loc.id));
        }
    }, [subscribedToAll, locations, setSelectedLocations]);

    const updateSelection = ({ id, changeAll }: { id: string; changeAll: boolean }): void => {
        let selectedItems: string[];
        if (changeAll) {
            const allSelected = selectedLocations.length === locations.length;
            selectedItems = allSelected ? [] : locations.map(loc => loc.id);
            if (setSubscribedToAll) {
                setSubscribedToAll(!allSelected);
            }
        } else {
            const isSelected = selectedLocations.includes(id);
            selectedItems = isSelected ? selectedLocations.filter(el => el !== id) : [...selectedLocations, id];
            if (setSubscribedToAll) {
                setSubscribedToAll(selectedItems.length === locations.length);
            }
        }
        setSelectedLocations(selectedItems);
    };

    if (loading) return <div />;
    return (
        <SimpleTreeView
            listId="locations-selector"
            options={locations.map(it => ({ name: it.name, id: it.id }))}
            selectedOptions={selectedLocations}
            onChange={updateSelection}
        />
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        locations: { locations, loading },
    } = state;
    return {
        locations,
        loading,
    };
};

export default connect(mapStateToProps)(LocationsSelectorComponent);
