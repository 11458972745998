import React, { ReactElement, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import { getSelectedGroupFromStorage } from 'commons/src/components/findUserType';
import NumberInput from 'commons/src/components/input/Number';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ModalWrapper from 'commons/src/components/modals/ModalWrapper';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';
import { SensorTypes, ThresholdType } from 'commons/src/models/commonEnums';
import { ErrorType, SelectedPeriod } from 'commons/src/models/commonTypeScript';
import { fetchSpaces, fetchSpaceSensorData } from '../../../../actions/spaceActions';
import { updateThresholdsApi, UpdateThresholdsRequest } from '../../../../api/thresholdsApi';
import { ThresholdsResourceScope } from '../../../../models/commonEnums';
import { SpaceThresholds } from '../../../../models/spaceModels';
import styles from './SpaceThresholdsModal.module.scss';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    sensorType: string;
    thresholds: SpaceThresholds;
    locationId: string;
    spaceId: string;
    selectedPeriod: SelectedPeriod;
};

const thresholdOrder = Object.values(ThresholdType);

const SpaceThresholdsModalComponent = ({
    onClose,
    sensorType,
    thresholds,
    locationId,
    spaceId,
    selectedPeriod,
    isOpen,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const sortedThresholdOptions = [...thresholds.thresholdOptions].sort(
        (a, b) => thresholdOrder.indexOf(a.type) - thresholdOrder.indexOf(b.type)
    );

    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorType | undefined>(undefined);

    useEffect((): void => {
        setSelectedValues(sortedThresholdOptions.map(it => it.value.toString(10)));
    }, []);

    const prevLoadingRef: React.MutableRefObject<boolean> = useRef(loading);
    useEffect(() => {
        if (prevLoadingRef.current && !loading && !error) {
            onClose();
        }
        prevLoadingRef.current = loading;
    }, [loading, prevLoadingRef]);

    const updateThresholds = useCallback(async (request: UpdateThresholdsRequest): Promise<void> => {
        setLoading(true);
        await updateThresholdsApi(request)
            .then(() => {
                setLoading(false);
                dispatch(fetchSpaces(locationId));
                dispatch(fetchSpaceSensorData(locationId, spaceId, selectedPeriod));
            })
            .catch(err => {
                setLoading(false);
                setError(err);
            });
    }, []);

    const onSave = (): void => {
        const userGroupId = getSelectedGroupFromStorage()?.userGroupId ?? '';
        const request: UpdateThresholdsRequest = {
            resourceScope: ThresholdsResourceScope.USER_GROUP,
            id: userGroupId,
            sensors: [
                {
                    sensor: sensorType as keyof typeof SensorTypes,
                    thresholds: Object.entries(selectedValues).map(([index, value]) => ({
                        thresholdType: sortedThresholdOptions[parseInt(index, 10)].type,
                        value: value ? parseInt(value, 10) : 0,
                    })),
                },
            ],
        };
        updateThresholds(request).catch();
    };

    const getDotColor = (thresholdType: ThresholdType): ReactElement => (
        <div
            className={
                [ThresholdType.LOW_ALERT, ThresholdType.HIGH_ALERT].includes(thresholdType)
                    ? styles.dotColorRed
                    : styles.dotColorYellow
            }
        />
    );

    const updateThreshold = (event: SyntheticEvent<HTMLInputElement>, index: number): void => {
        const { value } = event.currentTarget;
        const updatedValues = [...selectedValues];
        updatedValues[index] = value;
        setSelectedValues(updatedValues);
    };

    const customHeader: ReactElement = (
        <div className={styles.header}>
            <div className={styles.headerSensor}>
                <SensorIcon sensor={sensorType} />
                <div>{txt('Space.SensorThresholds', { sensor: txt(sensorType) })}</div>
            </div>
            <TertiaryButton onClick={onClose} noColor icon={<MaterialIcon name="close" />} />
        </div>
    );

    const isValidNumberInput = (index: number): boolean => {
        const parseToNumber = (value: string | undefined): number | undefined =>
            value !== undefined ? Number(value) : undefined;

        const currentValue = parseToNumber(selectedValues[index]);
        const previousValue = parseToNumber(selectedValues[index - 1]);
        const nextValue = parseToNumber(selectedValues[index + 1]);

        return (
            currentValue !== undefined &&
            (previousValue === undefined || currentValue > previousValue) &&
            (nextValue === undefined || currentValue < nextValue)
        );
    };

    const validateInputValues = (): boolean =>
        sortedThresholdOptions.every((_, index) => selectedValues[index] !== undefined);
    return (
        <ModalWrapper isOpen={isOpen} onClose={onClose} customHeader={customHeader} size="small">
            <div className={styles.body}>
                <div className={styles.inputs}>
                    {sortedThresholdOptions.map((option, index) => {
                        return (
                            <div className={styles.inputWrapper} key={option.type}>
                                <div className={styles.inputTitle}>
                                    {getDotColor(option.type)}
                                    <div>{txt(`ThresholdType.${option.type}`)}</div>
                                </div>
                                <NumberInput
                                    id={option.type}
                                    validate={!isValidNumberInput(index)}
                                    currentValue={selectedValues[index]}
                                    size="small"
                                    minValue={thresholds?.customOptions?.minSelectableValue}
                                    maxValue={thresholds?.customOptions?.maxSelectableValue}
                                    step={thresholds.customOptions?.thresholdDelta || 1}
                                    onChange={(event: SyntheticEvent<HTMLInputElement>): void =>
                                        updateThreshold(event, index)
                                    }
                                />
                            </div>
                        );
                    })}
                </div>
                {error && (
                    <div className={styles.errorWrapper}>
                        <ResponseBox text={`ErrorCodes.${error.error}`} />
                    </div>
                )}
                <div className={styles.button}>
                    <PrimaryButton
                        title={txt('Save')}
                        disabled={!validateInputValues()}
                        onClick={onSave}
                        color="primary"
                        loading={loading}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default SpaceThresholdsModalComponent;
