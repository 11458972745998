import { LocalStorageKeys } from '../constants';
import { GroupType } from '../models/commonEnums';
import { SelectedGroup } from '../models/commonTypeScript';

export const getSelectedGroupFromStorage = (): SelectedGroup | null => {
    const selectedGroup: string | null = localStorage.getItem(LocalStorageKeys.SelectedGroup);

    if (selectedGroup !== null && selectedGroup?.length !== 0) {
        try {
            return JSON.parse(selectedGroup);
        } catch (e) {
            return null;
        }
    }
    return null;
};

export const setSelectedGroupInStorage = (selectedGroup: SelectedGroup): void =>
    localStorage.setItem(LocalStorageKeys.SelectedGroup, JSON.stringify(selectedGroup));

export const userIsHbs = (): boolean => {
    const groupType: GroupType | undefined = localStorage && getSelectedGroupFromStorage()?.groupType;
    return !!groupType && groupType !== GroupType.consumer;
};
