import * as Highcharts from 'highcharts';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import { getValidLanguageTagForMoment } from 'commons/src/commonFunctions';
import { colors, dateFormats, dayIndex } from 'commons/src/constants';
import { BuildingType, Context, DayUsageHours } from 'commons/src/models/commonTypeScript';
import { BuildingProps } from '../../models/commonEnums';

export type Bands = {
    color: string;
    from: number;
    to: number;
}[];

export const validateBuildingData = (
    { txt }: Context,
    building: BuildingType,
    optionalBuildingProps: string[]
): string[] => {
    if (!building) return ['building'];
    const missingElements = [];
    if (!building.address || building.address.length === 0) {
        missingElements.push(txt('Address'));
    }
    if (!building.buildingType && !optionalBuildingProps.includes(BuildingProps.floors)) {
        missingElements.push(txt('NumberOfFloors'));
    }
    if (!building.buildingType && !optionalBuildingProps.includes(BuildingProps.buildingType)) {
        missingElements.push(txt('BuildingType'));
    }
    if (
        !optionalBuildingProps.includes(BuildingProps.buildingYear) &&
        (!building.buildingYear || building.buildingYear < 1000)
    ) {
        missingElements.push(txt('BuildingYear'));
    }
    if (!optionalBuildingProps.includes(BuildingProps.ventilation) && !building.ventilationType) {
        missingElements.push(txt('Ventilation'));
    }
    return missingElements;
};

export const createObserverAndFetchSvg = (elementId: string, getSvg: () => void): MutationObserver => {
    return new MutationObserver((mutations, obs) => {
        const resultSectionInView = document.getElementById(elementId);
        if (resultSectionInView) {
            getSvg();
            obs.disconnect();
        }
    });
};

export const validUsageHours = (building: BuildingType, optionalBuildingProps: string[]): boolean => {
    if (optionalBuildingProps.includes(BuildingProps.openingHours)) return true;
    if (!building || !building.usageHours) return false;
    const alwaysClosed = !Object.values(building.usageHours).some(day => !day.closed);
    const allWeekdaysSet = Object.keys(building.usageHours).length === 7;
    const hasInvalidDates = Object.keys(building.usageHours).some(
        day => !building.usageHours[day].closed && !(building.usageHours[day].from && building.usageHours[day].to)
    );
    return allWeekdaysSet && !hasInvalidDates && !alwaysClosed;
};

export const timeToDecimal = (timestamp: string): number => {
    const arr = timestamp.split(':');
    const dec = parseInt(arr[1], 10) / 60;
    return parseInt(arr[0], 10) + dec;
};

export const weekdayDateString = (date: number, dateFormat: keyof typeof dateFormats): string => {
    const validLanguageKey = getValidLanguageTagForMoment();
    const weekday = moment.utc(date).locale(validLanguageKey).format('dddd');
    const dateString = moment.utc(date).locale(validLanguageKey).format(dateFormats[dateFormat].shortFormat);
    const timeString = moment.utc(date).locale(validLanguageKey).format(dateFormats[dateFormat].timeOfDay);

    return `${weekday} ${dateString} ${timeString}`;
};

const getDayIndex = (obj: { [day: string]: number }, key: string): number => obj[key];

export const highChartsTimeZoneOffset = (timezone: string, useUTC = true): void => {
    Highcharts.setOptions({
        time: {
            useUTC,
            timezoneOffset: -moment.tz(timezone).utcOffset(),
        },
    });
};

export const getSortedWeek = (usageHours: { [day: string]: DayUsageHours }): string[] =>
    Object.keys(usageHours).sort((day1, day2) => getDayIndex(dayIndex, day1) - getDayIndex(dayIndex, day2));

export const generatePlotBands = (
    usageHours: { [day: string]: DayUsageHours },
    from: Moment | null,
    timeZone: string
): { color: string; from: number; to: number }[] => {
    const sortedWeek = getSortedWeek(usageHours);
    const numberOfWeeks = from ? moment().diff(moment(from), 'weeks') + 1 : 1;

    return sortedWeek.reduce((bands: Bands, day) => {
        const fromTime = usageHours[day].from;
        const toTime = usageHours[day].to;
        if (fromTime && toTime && !usageHours[day].closed) {
            const lastDayOfType = moment()
                .isoWeekday(getDayIndex(dayIndex, day) + 1)
                .format('YYYY-MM-DD');
            const lastFromTime = moment.tz(`${lastDayOfType} ${fromTime}`, timeZone);
            const lastToTime = moment.tz(`${lastDayOfType} ${toTime}`, timeZone);
            bands.push({
                color: colors.greyPorcelain,
                from: parseInt(moment(lastFromTime).format('x'), 10),
                to: parseInt(moment(lastToTime).format('x'), 10),
            });
            const weekArray = Array(numberOfWeeks).fill(undefined);
            weekArray.forEach((daySlot, index) => {
                const bandStart = moment(lastFromTime)
                    .subtract(index + 1, 'weeks')
                    .format('x');
                const bandEnd = moment(lastToTime)
                    .subtract(index + 1, 'weeks')
                    .format('x');
                bands.push({
                    color: colors.greyPorcelain,
                    from: parseInt(bandStart, 10),
                    to: parseInt(bandEnd, 10),
                });
            });
        }
        return bands;
    }, []);
};

const hasDataWithinPeriod = (startDate: number, endDate: number, data: number[][]): boolean => {
    const hasData = data.findIndex(dataPoint => dataPoint[0] > startDate && dataPoint[0] < endDate);
    return hasData >= 0;
};

export const addNullPointsToChartData = (
    data: number[][],
    startDate: Moment | null,
    endDate: Moment | null
): [number, number | null][] => {
    if (startDate === null || endDate === null) {
        return data as [number, number | null][];
    }
    if (data.length > 0) {
        let prevPointTime: number = data[0][0];
        const graphStartPoint: number = startDate.valueOf();
        const graphEndPoint: number = endDate.valueOf();
        const nullPointRange = graphEndPoint - graphStartPoint <= 20 * 60 * 60 * 1000 ? 10 : 20;
        const nullPointDistance: number = (graphEndPoint - graphStartPoint) / nullPointRange;
        const hasDataInPeriod: boolean = hasDataWithinPeriod(graphStartPoint, graphEndPoint, data);

        return data.reduce((addedNullPointsArray: [number, number | null][], dataPoint) => {
            if (hasDataInPeriod) {
                const initialDataPoint = dataPoint[0];
                if (addedNullPointsArray.length > 0 && initialDataPoint - prevPointTime > nullPointDistance) {
                    addedNullPointsArray.push([prevPointTime + nullPointDistance / 2, null]);
                }
                prevPointTime = initialDataPoint;
                addedNullPointsArray.push(dataPoint as [number, number | null]);
            }
            return addedNullPointsArray;
        }, []);
    }
    return data as [number, number | null][];
};

export default generatePlotBands;
