import { DeviceTypeNames, SensorTypes } from './models/commonEnums';

export const graphColorSensors: SensorTypes[] = [
    SensorTypes.radon,
    SensorTypes.radonShortTermAvg,
    SensorTypes.hourlyRadon,
    SensorTypes.hourlyRadonStandardDeviation,
    SensorTypes.temp,
    SensorTypes.co2,
    SensorTypes.voc,
    SensorTypes.humidity,
    SensorTypes.mold,
    SensorTypes.virusRisk,
    SensorTypes.staleAir,
    SensorTypes.transmissionEfficiency,
    SensorTypes.virusSurvivalRate,
    SensorTypes.pm1,
    SensorTypes.pm10,
    SensorTypes.pm25,
    SensorTypes.outdoorPm1,
    SensorTypes.outdoorPm10,
    SensorTypes.outdoorPm25,
];
export const virtualSensors = [
    SensorTypes.occupancy,
    SensorTypes.ventilationAmount,
    SensorTypes.ventilationRate,
    SensorTypes.airExchangeRate,
    SensorTypes.ventilationRunning,
    SensorTypes.occupants,
    SensorTypes.hourlyRadon,
    SensorTypes.hourlyRadonStandardDeviation,
    SensorTypes.historicVentilationRunning,
    SensorTypes.mold,
    SensorTypes.virusRisk,
    SensorTypes.presence,
];
export const riskFactorSensors = [
    SensorTypes.staleAir,
    SensorTypes.virusSurvivalRate,
    SensorTypes.transmissionEfficiency,
];
export const sensorTypesWithCustomThresholds: SensorTypes[] = [
    SensorTypes.radonShortTermAvg,
    SensorTypes.temp,
    SensorTypes.humidity,
    SensorTypes.co2,
    SensorTypes.co2,
    SensorTypes.voc,
    SensorTypes.pm25,
    SensorTypes.pm10,
    SensorTypes.hourlyRadon,
];

export const spaceGraphSensorOrder = [
    SensorTypes.temp,
    SensorTypes.co2,
    SensorTypes.voc,
    SensorTypes.hourlyRadon,
    SensorTypes.pm25,
    SensorTypes.pm1,
    SensorTypes.pm10,
    SensorTypes.humidity,
    SensorTypes.pressure,
    SensorTypes.sla,
    SensorTypes.light,
    SensorTypes.lux,
    SensorTypes.presence,
    SensorTypes.occupants,
    SensorTypes.airExchangeRate,
    SensorTypes.virusRisk,
    SensorTypes.mold,
];
export const wantedInInletAir = [SensorTypes.co2, SensorTypes.temp, SensorTypes.humidity, SensorTypes.outdoorTemp];
export const alertSensors = [
    SensorTypes.voc,
    SensorTypes.co2,
    SensorTypes.humidity,
    SensorTypes.temp,
    SensorTypes.pressure,
    SensorTypes.pm25,
];
export const alertInsightSensors: SensorTypes[] = [SensorTypes.virusRisk, SensorTypes.mold];
export const thresholdsModalSensors = [
    SensorTypes.radonShortTermAvg,
    SensorTypes.voc,
    SensorTypes.humidity,
    SensorTypes.co2,
    SensorTypes.temp,
    SensorTypes.pm25,
];

export const iaqCertificateSensors = [
    SensorTypes.co2,
    SensorTypes.voc,
    SensorTypes.humidity,
    SensorTypes.temp,
    SensorTypes.radonShortTermAvg,
    SensorTypes.pm25,
    SensorTypes.pm10,
    SensorTypes.sla,
    SensorTypes.pm1,
];

export const iaqInsightReportSensors = [
    SensorTypes.co2,
    SensorTypes.voc,
    SensorTypes.humidity,
    SensorTypes.temp,
    SensorTypes.pm1,
    SensorTypes.pm10,
    SensorTypes.pm25,
    SensorTypes.light,
    SensorTypes.sla,
    SensorTypes.mold,
];

export const outdoorInsightSensors = [SensorTypes.temp, SensorTypes.humidity, SensorTypes.pressure];

export const csvSensors = [
    SensorTypes.co2,
    SensorTypes.voc,
    SensorTypes.temp,
    SensorTypes.humidity,
    SensorTypes.pressure,
    SensorTypes.pm25,
    SensorTypes.pm10,
    SensorTypes.pm1,
    SensorTypes.radonShortTermAvg,
    SensorTypes.sla,
    SensorTypes.light,
    SensorTypes.lux,
];
export const csvVirtualSensors: SensorTypes[] = [SensorTypes.mold, SensorTypes.virusRisk, SensorTypes.hourlyRadon];
export const buildingFocusSensorsInAllDevices = [SensorTypes.temp, SensorTypes.humidity];

export const alertRulesSingleThresholdSensors = [
    SensorTypes.co2,
    SensorTypes.voc,
    SensorTypes.virusRisk,
    SensorTypes.pm25,
    SensorTypes.mold,
];

export const buildingTileSensorTypes = [SensorTypes.co2, SensorTypes.temp];

export const devicesWithBatterySettings = [
    DeviceTypeNames.wavePlus,
    DeviceTypeNames.waveCo2,
    DeviceTypeNames.spaceCo2Mini,
    DeviceTypeNames.viewPlus,
    DeviceTypeNames.viewPlusBusiness,
    DeviceTypeNames.viewCo2,
    DeviceTypeNames.waveNano,
];

export const moldRiskDevices = [DeviceTypeNames.wavePlus, DeviceTypeNames.waveNano];

export const airthingsSensorDevices = [
    DeviceTypeNames.wavePlus,
    DeviceTypeNames.waveCo2,
    DeviceTypeNames.waveEnhance,
    DeviceTypeNames.wave,
    DeviceTypeNames.wave2,
    DeviceTypeNames.mini,
    DeviceTypeNames.ternCo2,
    DeviceTypeNames.spaceCo2Mini,
    DeviceTypeNames.viewPlus,
    DeviceTypeNames.viewPlusBusiness,
    DeviceTypeNames.viewPollution,
    DeviceTypeNames.viewRadon,
    DeviceTypeNames.viewCo2,
    DeviceTypeNames.spacePlus,
    DeviceTypeNames.spaceHub,
];
export const subscriptionDevices = [
    DeviceTypeNames.viewPlusBusiness,
    DeviceTypeNames.wavePlus,
    DeviceTypeNames.viewCo2,
    DeviceTypeNames.spaceCo2Mini,
    DeviceTypeNames.waveNano,
];
export const oldSubscriptionDevices = [
    DeviceTypeNames.waveCo2,
    DeviceTypeNames.mini,
    DeviceTypeNames.hub,
    DeviceTypeNames.spaceHub,
];
export const subscriptionDevicesIncludingOldOnce = [
    ...subscriptionDevices,
    ...oldSubscriptionDevices.filter(device => ![DeviceTypeNames.spaceHub, DeviceTypeNames.hub].includes(device)),
];

export const devicesThatCanBeAddedThroughTheDashboard: DeviceTypeNames[] = [
    DeviceTypeNames.spaceCo2Mini,
    DeviceTypeNames.viewCo2,
    DeviceTypeNames.viewPlusBusiness,
    DeviceTypeNames.waveNano,
    DeviceTypeNames.wavePlus,
    DeviceTypeNames.mini,
    DeviceTypeNames.hub,
];

export const viewDevices = [
    DeviceTypeNames.viewPlus,
    DeviceTypeNames.viewPlusBusiness,
    DeviceTypeNames.viewCo2,
    DeviceTypeNames.viewRadon,
    DeviceTypeNames.viewPollution,
];

export const devicesWithCo2 = [
    DeviceTypeNames.wavePlus,
    DeviceTypeNames.waveCo2,
    DeviceTypeNames.ternCo2,
    DeviceTypeNames.spaceCo2Mini,
    DeviceTypeNames.viewPlusBusiness,
    DeviceTypeNames.viewCo2,
    DeviceTypeNames.viewPlus,
];
// this list is wrong - should it be updated?
export const devicesWithVoc = [DeviceTypeNames.wavePlus, DeviceTypeNames.viewPlusBusiness];
export const devicesWithPM25 = [DeviceTypeNames.viewPlusBusiness];

export const iaqInsightReportDevices = [
    DeviceTypeNames.wavePlus,
    DeviceTypeNames.mini,
    DeviceTypeNames.viewPlus,
    DeviceTypeNames.viewPlusBusiness,
    DeviceTypeNames.viewCo2,
    DeviceTypeNames.waveCo2,
    DeviceTypeNames.spaceCo2Mini,
    DeviceTypeNames.waveNano,
];

export const virusRiskDeviceTypes = [
    DeviceTypeNames.wavePlus,
    DeviceTypeNames.waveCo2,
    DeviceTypeNames.viewPlusBusiness,
    DeviceTypeNames.viewPlus,
    DeviceTypeNames.viewCo2,
    DeviceTypeNames.spaceCo2Mini,
];

export const virusRiskFooterDevices = [DeviceTypeNames.wavePlus, DeviceTypeNames.waveCo2];

export const radonInsightReportDevices = [DeviceTypeNames.wavePlus];

export const outdoorInsightDevices = [
    DeviceTypeNames.wavePlus,
    DeviceTypeNames.waveCo2,
    DeviceTypeNames.mini,
    DeviceTypeNames.viewPlus,
    DeviceTypeNames.viewPlusBusiness,
    DeviceTypeNames.viewCo2,
    DeviceTypeNames.spaceCo2Mini,
];

export const iaqCertificateDevices = [
    DeviceTypeNames.wavePlus,
    DeviceTypeNames.mini,
    DeviceTypeNames.viewPlus,
    DeviceTypeNames.viewPlusBusiness,
    DeviceTypeNames.viewCo2,
    DeviceTypeNames.spaceCo2Mini,
    DeviceTypeNames.waveNano,
];
