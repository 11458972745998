import React, { ReactElement, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import StatusPill from 'commons/src/components/pills/StatusPill';
import { roleRestrictions, sensorUnits } from 'commons/src/constants';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import { PillStatus, Rating, Role } from 'commons/src/models/commonEnums';
import { SelectedPeriod } from 'commons/src/models/commonTypeScript';
import { SpaceThresholds } from '../../../../models/spaceModels';
import { Store } from '../../../../reducers';
import styles from './SpaceThresholdsComponent.module.scss';
import SpaceThresholdsModal from './SpaceThresholdsModal';

type Props = {
    sensorType: string;
    thresholds: SpaceThresholds;
    locationId: string;
    spaceId: string;
    selectedPeriod: SelectedPeriod;
};

const SpaceThresholdsComponent = ({
    sensorType,
    thresholds,
    locationId,
    spaceId,
    selectedPeriod,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const [openModal, setOpenModal] = useState<boolean>(false);

    const userRole: Role = useSelector((store: Store) => store.userSettings.selectedGroup?.role || Role.VIEWER);

    const renderThresholds = (): ReactElement => {
        const [firstThreshold, lastThreshold] = [thresholds.ranges[0], thresholds.ranges[thresholds.ranges.length - 1]];
        return (
            <div className={styles.font}>
                {firstThreshold.rating === Rating.GOOD ? (
                    <Trans
                        i18nKey={txt('Space.ThresholdBelow', {
                            value: firstThreshold.to,
                            unit: sensorUnits[thresholds.unit],
                        })}
                        components={{ bold: <span className={styles.bold} /> }}
                    />
                ) : (
                    <Trans
                        i18nKey={txt('Space.ThresholdBetween', {
                            lowValue: firstThreshold.to,
                            highValue: lastThreshold.from,
                            unit: sensorUnits[thresholds.unit],
                        })}
                        components={{ bold: <span className={styles.bold} /> }}
                    />
                )}
            </div>
        );
    };
    return (
        <div className={styles.row}>
            <StatusPill status={PillStatus.success} outline size="small" />
            {renderThresholds()}
            {userRoleAboveRequiredLevel(userRole, roleRestrictions.editThresholds) && (
                <TertiaryButton
                    size="small"
                    title={txt('Edit')}
                    onClick={(): void => setOpenModal(true)}
                    testId="open-modal"
                />
            )}
            <SpaceThresholdsModal
                isOpen={openModal}
                key={`${sensorType}-${openModal}`}
                onClose={(): void => setOpenModal(false)}
                sensorType={sensorType}
                thresholds={thresholds}
                locationId={locationId}
                spaceId={spaceId}
                selectedPeriod={selectedPeriod}
            />
        </div>
    );
};

export default SpaceThresholdsComponent;
