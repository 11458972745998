import React from 'react';
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { getDotColor, mapDeviceType } from '../../../../commonFunctions';
import {
    BoldText,
    ColoredCircle,
    coloredCircleStyles,
    FlexWrapperSpaced,
    InsightWrapper,
    PageLineSlim,
    SensorValueMedium,
    SubHeader,
    ValueWrapper,
} from '../../../../components/PDF/PdfConstants';
import { sensorUnits } from '../../../../constants';
import { viewDevices } from '../../../../DeviceAndSensorLists';
import ViewImage from '../../../../img/deviceIcons/device-png-view.png';
import PlusImage from '../../../../img/deviceIcons/device-png-wave-plus.png';
import WaveImage from '../../../../img/deviceIcons/device-png-wave.png';
import RadonPng from '../../../../img/sensorIcons/sensorPng/sensor-radon.png';
import { DeviceTypeNames } from '../../../../models/commonEnums';
import { AnyDeviceType, PDFContext } from '../../../../models/commonTypeScript';

const componentStyle = StyleSheet.create({
    tileDescription: { maxWidth: '70vw', padding: '0 2vw' },
    deviceInfoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '-5',
    },
    deviceIcon: {
        width: 40,
        height: 40,
        padding: 5,
    },
});

export interface RadonInsightSensorData {
    serialNumber: string;
    radonStep1: number;
    radonStep1StdDev: number | undefined;
    radonStep1Lld: number | undefined;
}

type Props = {
    data: RadonInsightSensorData;
    segmentName: string;
    unit: keyof typeof sensorUnits;
    txt: PDFContext;
    floor: string;
    serialNumber: string;
    deviceType: string;
    roomType: string;
    thresholds: number[];
    numberOfDays: string;
};

const DeviceDetails = (props: Props): React.ReactElement => {
    const { segmentName, data, unit, txt, floor, serialNumber, deviceType, roomType, thresholds, numberOfDays } = props;

    const longTermRadonDescription = txt('RadonInsight.LongTermMeasurementConsumerText', {
        std: data.radonStep1StdDev ? `${data.radonStep1StdDev.toFixed(2)} ${sensorUnits[unit]}` : ' ',
    });
    const displayLldValue: boolean = !!(data.radonStep1Lld && data.radonStep1) && data.radonStep1Lld > data.radonStep1;
    let displayedValue: string | number | undefined = displayLldValue ? data.radonStep1Lld : data.radonStep1;
    const statusColor = getDotColor(thresholds, 'radon', displayLldValue ? data.radonStep1Lld : data.radonStep1);
    if (displayedValue) {
        displayedValue = sensorUnits[unit] === sensorUnits.pci ? displayedValue.toFixed(1) : displayedValue.toFixed(0);
    }
    let deviceIcon = WaveImage;
    if (deviceType === DeviceTypeNames.wavePlus) deviceIcon = PlusImage;
    else if (viewDevices.includes(deviceType as DeviceTypeNames)) deviceIcon = ViewImage;

    return (
        <View wrap={false}>
            <View>
                <FlexWrapperSpaced style={{ marginBottom: '20px' }}>
                    <View>
                        <BoldText>{txt('ResetConnection.DeviceName')}</BoldText>
                        <Text>{segmentName}</Text>
                    </View>
                    <View style={componentStyle.deviceInfoWrapper}>
                        <Image style={componentStyle.deviceIcon} src={deviceIcon} />
                        <View>
                            <Text>
                                {[
                                    txt('DeviceType'),
                                    ': ',
                                    txt(`${mapDeviceType(deviceType as AnyDeviceType)}FullName`),
                                ]}
                            </Text>
                            <Text>{`${txt('SerialNumber')}: ${serialNumber}`}</Text>
                        </View>
                    </View>
                    <View>
                        <BoldText>{txt('Floor')}</BoldText>
                        <Text>{floor !== undefined ? floor.toString() : txt('NotSet')}</Text>
                    </View>
                    <View>
                        <BoldText>{txt('RoomType')}</BoldText>
                        <Text>{roomType ? txt(`RoomTypeOptions.${roomType}`) : txt('NotSet')}</Text>
                    </View>
                    <View />
                </FlexWrapperSpaced>
            </View>
            <PageLineSlim />
            <FlexWrapperSpaced>
                <View>
                    <SubHeader>{txt('RadonInsight.LongTermMeasurementDays', { days: numberOfDays })}</SubHeader>
                    <InsightWrapper>
                        <ColoredCircle style={[coloredCircleStyles[statusColor]]}>
                            <Image style={componentStyle.deviceIcon} src={RadonPng} />
                        </ColoredCircle>
                        <ValueWrapper>
                            <Text>{unit && sensorUnits[unit]}</Text>
                            <SensorValueMedium>{`${displayLldValue ? '<' : ''}${displayedValue}`}</SensorValueMedium>
                        </ValueWrapper>
                        <Text style={componentStyle.tileDescription}>{longTermRadonDescription}</Text>
                    </InsightWrapper>
                </View>
            </FlexWrapperSpaced>
        </View>
    );
};

export default DeviceDetails;
