import { produce } from 'immer';
import { SelectGroupActionType, SelectGroupReducerActions } from 'commons/src/actions/selectGroupActions';
import { ExtraSeries, FullDeviceData, SensorData } from 'commons/src/models/commonTypeScript';
import { SpaceActions, SpaceDataActions } from '../actions/spaceActions';
import { StoreSpaceData } from '../models/spaceModels';

export interface SpaceDataStore {
    space: { [spaceId: string]: StoreSpaceData };
    sensorData: { [segmentId: string]: SensorData };
    deviceData: { [segmentId: string]: FullDeviceData };
    extraVirtualSeries: { [segmentId: string]: ExtraSeries };
}

const initialState: SpaceDataStore = {
    space: {},
    sensorData: {},
    deviceData: {},
    extraVirtualSeries: {},
};

/* eslint-disable no-param-reassign */
const reducer = (
    state: SpaceDataStore = initialState,
    action: SpaceDataActions | SelectGroupReducerActions
): SpaceDataStore =>
    // eslint-disable-next-line consistent-return
    produce(state, draft => {
        switch (action.type) {
            case SpaceActions.FetchSpaceSensorDataSuccess:
            case SpaceActions.FetchSpaceVirtualSensorDataSuccess: {
                action.spaceData.devices.forEach(device => {
                    const { segmentId } = device.deviceData;
                    const extraSeriesExists = state.extraVirtualSeries[segmentId] !== undefined;
                    if (device.extraSeries) {
                        if (!extraSeriesExists) {
                            draft.extraVirtualSeries[segmentId] = device.extraSeries;
                        } else {
                            Object.keys(device.extraSeries).forEach(sensorType => {
                                if (draft.extraVirtualSeries[segmentId][sensorType] === undefined) {
                                    draft.extraVirtualSeries[segmentId][sensorType] = device.extraSeries[sensorType];
                                } else {
                                    draft.extraVirtualSeries[segmentId][sensorType][action.fetchedInterval] =
                                        device.extraSeries[sensorType][action.fetchedInterval];
                                }
                            });
                        }
                    }

                    const deviceDataExists = state.deviceData[segmentId] !== undefined;
                    if (!deviceDataExists) {
                        draft.deviceData[segmentId] = device.deviceData;
                    } else {
                        draft.deviceData[segmentId].sensors = [
                            ...state.deviceData[segmentId].sensors,
                            ...device.deviceData.sensors,
                        ];
                    }

                    if (state.sensorData[segmentId] === undefined) {
                        draft.sensorData[segmentId] = device.sensorData;
                    } else {
                        Object.keys(device.sensorData).forEach(sensorType => {
                            if (draft.sensorData[segmentId][sensorType] === undefined) {
                                draft.sensorData[segmentId][sensorType] = device.sensorData[sensorType];
                            } else {
                                draft.sensorData[segmentId][sensorType][action.fetchedInterval] =
                                    device.sensorData[sensorType][action.fetchedInterval];
                            }
                        });
                    }
                });
                const spaceDevices = action.spaceData.devices.map(device => device.deviceData.segmentId);
                const currentSpaceDevices = draft.space[action.spaceId]?.devices ?? [];
                const allSpaceDevices = [...new Set([...currentSpaceDevices, ...spaceDevices])];
                const combinedThresholds = {
                    ...draft.space[action.spaceId]?.thresholds,
                    ...action.spaceData.thresholds,
                };
                draft.space = {
                    [action.spaceId]: { ...action.spaceData, thresholds: combinedThresholds, devices: allSpaceDevices },
                };
                break;
            }
            case SelectGroupActionType.SelectGroupInit:
                return initialState;
            default:
                break;
        }
    });

export default reducer;
