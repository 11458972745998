import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { dateFormats } from 'commons/src/constants';
import { subscriptionDevices } from 'commons/src/DeviceAndSensorLists';
import spinner from 'commons/src/img/spinner';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { ServicePeriod } from '../../../../models/commonEnums';
import { EstimatedSubscription, Subscription, SubscriptionSeats } from '../../../../models/subscriptionModels';
import AddSeatsTable from '../StartSubscription/AddSeatsTable';
import { getFormattedCost } from '../SubscriptionCommons';
import styles from './AdditionalSeatsView.module.scss';

export type ParentProps = {
    servicePeriod?: ServicePeriod;
    seatsPerDevice: SubscriptionSeats[];
    updateSeats: (newSeats: SubscriptionSeats[]) => void;
    dateFormat: keyof typeof dateFormats;
    subscription: Subscription;
    costPreview?: EstimatedSubscription;
    loadingPreview: boolean;
    costPreviewError?: ErrorType;
    ratePlanError?: ErrorType;
};

export const AdditionalSeatsView = ({
    servicePeriod,
    seatsPerDevice,
    updateSeats,
    dateFormat,
    subscription,
    costPreview,
    loadingPreview,
    costPreviewError,
    ratePlanError,
}: ParentProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const endDateFormatted = dayjs(subscription.subscriptionEndDate).format(dateFormats[dateFormat].format);
    const renewalDateFormatted = dayjs(subscription.renewalDate).format(dateFormats[dateFormat].format);

    const updateSeatsCount = (updatedSeats: SubscriptionSeats): void => {
        const { deviceType } = updatedSeats;
        const updatedSeatsPerDevice = seatsPerDevice;
        const indexOfDevice = updatedSeatsPerDevice.findIndex(element => element.deviceType === deviceType);
        updatedSeatsPerDevice[indexOfDevice] = updatedSeats;
        updateSeats([...updatedSeatsPerDevice]);
    };

    let seatsFromLegacyDevices = 0;
    const currentRenewalTotalSeats = subscription.deviceSeats.reduce((acc, seats) => {
        if (!subscriptionDevices.includes(seats.deviceType)) {
            seatsFromLegacyDevices += seats.seats;
        }
        return acc + seats.seats;
    }, 0);
    const currentTotalCostFormatted = getFormattedCost(subscription.currency, subscription.estimatedTotalCost);

    const newRenewalTotalSeats =
        seatsFromLegacyDevices +
        seatsPerDevice.reduce((acc, seats) => {
            return acc + seats.seats;
        }, 0);
    const newTotalCostFormatted = costPreview && getFormattedCost(costPreview.currency, costPreview.estimatedTotalCost);
    const customServicePlan = subscription.durationInYears && servicePeriod === undefined;
    return (
        <div>
            <div className={styles.header}>{txt('AddSeats.AdditionalSeats')}</div>
            <p className={styles.text}>
                {txt('AddSeats.NewSeatsDescription', { servicePeriodEndDate: endDateFormatted })}
            </p>
            <div className={styles.contentWrapper}>
                <div className={styles.seatsForm}>
                    {ratePlanError || customServicePlan ? (
                        <ResponseBox
                            text={
                                ratePlanError
                                    ? `ErrorCodes.${ratePlanError.error}`
                                    : 'Subscription.CustomServicePlanWarning'
                            }
                        />
                    ) : (
                        <AddSeatsTable
                            seatsPerDevice={seatsPerDevice}
                            updateSeats={updateSeatsCount}
                            servicePeriod={servicePeriod}
                            addingSeats
                        />
                    )}
                </div>
                <div className={styles.costDetails}>
                    {(subscription.discountPercentage || subscription.commitmentDiscountPercentage) && (
                        <div className={styles.discountInfo}>
                            <div className={styles.discountHeader}>{txt('AddSeats.IncludedDiscounts')}:</div>
                            {subscription.commitmentDiscountPercentage && (
                                <div>
                                    {txt('AddSeats.TermDiscount', { years: subscription.durationInYears })} (
                                    {subscription.commitmentDiscountPercentage}%)
                                </div>
                            )}
                            {subscription.discountPercentage && (
                                <div>
                                    {txt('AddSeats.CustomerDiscount')} ({subscription.discountPercentage}%)
                                </div>
                            )}
                        </div>
                    )}
                    <div>{txt('AddSeats.CurrentRenewalTotal')}</div>
                    <div className={styles.boldText}>
                        {currentRenewalTotalSeats} {txt('Subscription.Seats')}
                    </div>
                    <div className={styles.boldText}>{currentTotalCostFormatted}</div>
                    <MaterialIcon name="arrow_downward" />
                    <div>{txt('AddSeats.NewEstimatedRenewalTotal', { renewalDate: renewalDateFormatted })}</div>
                    <div className={styles.boldText}>{`${newRenewalTotalSeats} ${txt('Subscription.Seats')} (${
                        newRenewalTotalSeats - currentRenewalTotalSeats
                    } ${txt('AddSeats.Added')})`}</div>
                    {loadingPreview ? (
                        <div>{spinner}</div>
                    ) : (
                        seatsPerDevice.filter(seat => seat.seats > seat.deployedSeats).length > 0 && (
                            <div className={styles.boldText}>{newTotalCostFormatted}</div>
                        )
                    )}
                    {costPreviewError && <ResponseBox text={`ErrorCodes${costPreviewError.error}`} />}
                </div>
            </div>
        </div>
    );
};

export default AdditionalSeatsView;
