import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { analyticsLogger } from 'commons/src/analytics';
import { SPACE_COMPARE_SENSORS } from 'commons/src/analytics/AnalyticsEvents';
import { sortSensors } from 'commons/src/commonFunctions';
import { customPeriodName, sensorGraphPeriods } from 'commons/src/constants';
import { spaceGraphSensorOrder } from 'commons/src/DeviceAndSensorLists';
import { setXAxisMinValue } from 'commons/src/features/devicePage/SensorConfig';
import { FeatureToggleEnum, SensorTypes } from 'commons/src/models/commonEnums';
import { SelectedPeriod } from 'commons/src/models/commonTypeScript';
import { Digest } from '../../../../models/eventAggregationModels';
import { SpaceThresholds } from '../../../../models/spaceModels';
import { Store } from '../../../../reducers';
import { spaceSensorData } from '../spaceSelectors';
import { setThresholds } from './spaceGraphFunctions';
import 'react-multi-carousel/lib/styles.css';

export enum SensorGraphView {
    LIST = 'list',
    FOCUS = 'focus',
}

type Props = {
    spaceId: string;
};

type Zone = {
    value?: number;
    color: string;
};

interface UseSpaceGraphSectionReturn {
    selectedPeriod: SelectedPeriod;
    setSelectedPeriod: React.Dispatch<React.SetStateAction<SelectedPeriod>>;
    selectedSensor: SensorTypes;
    thresholds: { [sensor: string]: SpaceThresholds };
    compareSensor: SensorTypes | undefined;
    onSelectCompareSensor: (sensor: SensorTypes | string) => void;
    digests: Digest[];
    enableEventAggregationToggleOn: boolean | undefined;
    isFocusView: boolean;
    onChangeView: () => void;
    sortedSensors: { type: SensorTypes }[];
    sparkLineSensorData: Array<{
        sensorData: {
            data: number[][];
            id: string;
            zones: Zone[];
        };
        sensor: SensorTypes;
        lastDataPoint:
            | {
                  x: number;
                  y: number;
                  marker: { radius: number; symbol: string };
              }
            | undefined;
    }>;
    responsive: {
        [key: string]: {
            breakpoint: { max: number; min: number };
            items: number;
            slidesToSlide: number;
        };
    };
    graphStartAndEnd: { min: number; max: number };
    sensorCompareOptions: Array<{
        id: string;
        inputValue: string;
    }>;
    handleStatusPillClick: (digest: Digest) => void;
    showCustomThresholdsToggleOn: boolean;
    updateSelectedSensor: (sensor: SensorTypes) => void;
}

export const useSpaceGraphSection = ({ spaceId }: Props): UseSpaceGraphSectionReturn => {
    const { t: txt } = useTranslation();
    const [selectedPeriod, setSelectedPeriod] = useState<SelectedPeriod>(sensorGraphPeriods.week);
    const [selectedSensor, setSelectedSensor] = useState<SensorTypes>(SensorTypes.temp);
    const [compareSensor, setCompareSensor] = useState<SensorTypes | undefined>(undefined);
    const {
        spaceData: { sensorData, sensorsInSpace, thresholds },
    } = useSelector((state: Store) => spaceSensorData(state, spaceId));
    const { digests } = useSelector((state: Store) => state.eventAggregation.aggregates);
    const enableEventAggregationToggleOn = useSelector((state: Store) => state.toggles.enableEventAggregationToggleOn);

    const onSelectCompareSensor = (sensor: SensorTypes | string): void => {
        if (sensor === 'Space.ClearCompareSelection') {
            setCompareSensor(undefined);
        } else {
            setCompareSensor(sensor as SensorTypes);
            analyticsLogger(SPACE_COMPARE_SENSORS, { active: selectedSensor, comparison: sensor });
        }
    };
    const [searchParams, setSearchParams] = useSearchParams();
    const initialView = searchParams.get('view') !== SensorGraphView.LIST;
    const [isFocusView, setIsFocusView] = useState(initialView);

    useEffect(() => {
        if (!searchParams.has('view')) {
            searchParams.set('view', SensorGraphView.FOCUS);
            setSearchParams(searchParams);
        }
    }, [searchParams, setSearchParams]);

    const showCustomThresholdsToggleOn = useSelector((store: Store) =>
        store.userSettings.featureToggles.includes(FeatureToggleEnum.enableCustomThresholds)
    );

    const onChangeView = (): void => {
        const newView = isFocusView ? SensorGraphView.LIST : SensorGraphView.FOCUS;
        searchParams.set('view', newView);
        setSearchParams(searchParams);
        setIsFocusView(!isFocusView);
    };

    const updateSelectedSensor = (sensor: SensorTypes): void => {
        setCompareSensor(undefined);
        setSelectedSensor(sensor);
    };

    const sortedSensors = useMemo(
        () =>
            sortSensors(
                sensorsInSpace.map(sensor => ({ type: sensor })),
                spaceGraphSensorOrder,
                sensorsInSpace.length
            ),
        [sensorsInSpace]
    );
    const findDeviceWithDataForSensorSparkLine = (sensor: SensorTypes): number[][] => {
        const device = Object.keys(sensorData).find(segmentId => {
            const withData = sensorData[segmentId]?.[sensor]?.[selectedPeriod.name];
            return withData && withData.length > 0;
        });
        return device ? sensorData[device]?.[sensor]?.[selectedPeriod.name] : [];
    };
    const sparkLineSensorData = useMemo(
        () =>
            sortedSensors.map(sensor => {
                const sensorGraphData = findDeviceWithDataForSensorSparkLine(sensor.type);
                const lastPoint = sensorGraphData?.length > 0 ? sensorGraphData[sensorGraphData.length - 1] : undefined;
                const ranges = (thresholds && thresholds[sensor.type]?.ranges) || [];
                const zoneColors = setThresholds(ranges || []);
                const graphName =
                    selectedPeriod.name === customPeriodName
                        ? `${selectedPeriod.startDate?.format()}-${selectedPeriod?.endDate?.format()}`
                        : selectedPeriod.name;
                const lastDataPoint = lastPoint && {
                    x: lastPoint[0],
                    y: lastPoint[1],
                    marker: { radius: 3, symbol: 'circle' },
                };
                return {
                    sensorData: {
                        data: sensorGraphData,
                        id: `spark-line-${sensor.type}-${graphName}`,
                        zones: zoneColors,
                    },
                    sensor: sensor.type as SensorTypes,
                    lastDataPoint,
                };
            }),
        [sensorData, sortedSensors]
    );

    const responsive = {
        sixSensors: {
            breakpoint: { max: 3000, min: 1220 },
            items: 6,
            slidesToSlide: 3,
        },
        fiveSensors: {
            breakpoint: { max: 1220, min: 1080 },
            items: 5,
            slidesToSlide: 3,
        },
        fourSensors: {
            breakpoint: { max: 1080, min: 910 },
            items: 4,
            slidesToSlide: 3,
        },
        threeSensors: {
            breakpoint: { max: 910, min: 730 },
            items: 3,
            slidesToSlide: 3,
        },
        twoSensors: {
            breakpoint: { max: 730, min: 460 },
            items: 2,
            slidesToSlide: 2,
        },
        oneSensors: {
            breakpoint: { max: 460, min: 0 },
            items: 1,
            slidesToSlide: 1,
        },
    };

    const graphStartAndEnd = useMemo(
        () => ({
            min: setXAxisMinValue(selectedPeriod),
            max: selectedPeriod.endDate ? moment(selectedPeriod.endDate).valueOf() : moment().valueOf(),
        }),
        [selectedPeriod]
    );

    const sensorCompareOptions = (
        compareSensor ? [{ type: 'Space.ClearCompareSelection' }, ...sortedSensors] : sortedSensors
    )
        .map(sensor => ({
            id: sensor.type,
            inputValue: txt(sensor.type),
        }))
        .filter(option => option.id !== selectedSensor);

    const handleStatusPillClick = (digest: Digest): void => {
        updateSelectedSensor(digest.sensorType as SensorTypes);
    };

    return {
        selectedPeriod,
        setSelectedPeriod,
        selectedSensor,
        compareSensor,
        onSelectCompareSensor,
        digests,
        enableEventAggregationToggleOn,
        isFocusView,
        onChangeView,
        sortedSensors,
        sparkLineSensorData,
        responsive,
        graphStartAndEnd,
        sensorCompareOptions,
        handleStatusPillClick,
        showCustomThresholdsToggleOn,
        updateSelectedSensor,
        thresholds,
    };
};
