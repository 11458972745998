import React, { Fragment, KeyboardEvent, SyntheticEvent } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { mapDeviceType } from 'commons/src/commonFunctions';
import SearchDropdown from 'commons/src/components/dropdown/SearchDropdown';
import Error from 'commons/src/components/errorComponents/Error';
import CheckBox from 'commons/src/components/input/Checkbox';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import DeviceIcon from 'commons/src/img/deviceIcons/icon_device';
import { AnyDeviceType, SegmentProperties, BuildingType, DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import { FetchSegmentProperties, fetchSegmentProperties } from '../../../actions/segmentPropertiesActions';
import DeviceSelectorWithDetails from '../../../components/DeviceSelectorWithDetails';
import { Store } from '../../../reducers';

interface ActionProps {
    getSegmentProperties: (serialNumber: string) => void;
}

type StateProps = {
    error: boolean;
    devices: { [serialNumber: string]: DeviceWithKeyInfo };
    segmentProperties: { [serialNumber: string]: SegmentProperties };
};

export type ParentProps = {
    building: BuildingType | undefined;
    setSelectedDevices: (selectedDevices: DeviceWithKeyInfo[]) => void;
    selectedDevices: DeviceWithKeyInfo[];
    selectSingleDevice?: boolean;
    acceptedDeviceTypes: AnyDeviceType[];
    minDaysMeasured: number;
};
type OptionSelector = { id: string; name: string };

export type Props = StateProps & ActionProps & ParentProps;

export const DeviceInsightComponent = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const {
        acceptedDeviceTypes,
        building,
        error,
        devices,
        getSegmentProperties,
        segmentProperties,
        setSelectedDevices,
        selectedDevices,
        selectSingleDevice,
        minDaysMeasured,
    } = props;

    if (error) {
        return <Error />;
    }

    const selectDevice = (selected: OptionSelector): void => {
        const device = devices[selected.id];
        setSelectedDevices([...selectedDevices, device]);
        if (!segmentProperties[device.serialNumber]) {
            getSegmentProperties(selected.id);
        }
    };

    const allAcceptedDevices = building
        ? building.devices
              .map(serialNumber => devices[serialNumber])
              .filter(device => device && acceptedDeviceTypes.includes(device.type))
        : [];

    const removeDeviceFromList = (e: SyntheticEvent<HTMLInputElement> | KeyboardEvent<HTMLButtonElement>): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (e?.key && e.key !== 'Enter') {
            return;
        }
        const deviceToRemove = e.currentTarget.value;
        const updatedList = selectedDevices.filter(device => device.serialNumber !== deviceToRemove);
        setSelectedDevices(updatedList);
    };

    const checkAllDevices = (): void => {
        if (selectedDevices.length === allAcceptedDevices.length) {
            setSelectedDevices([]);
        } else {
            setSelectedDevices(allAcceptedDevices);
            allAcceptedDevices.forEach(radonDevice => {
                if (!segmentProperties[radonDevice.serialNumber]) {
                    getSegmentProperties(radonDevice.serialNumber);
                }
            });
        }
    };

    const lessThanRequiredDaysAgo = (startDate: string): boolean => {
        const daysAgo = moment().diff(moment(startDate), 'days');
        return daysAgo < minDaysMeasured;
    };

    const locationDevices = building
        ? building.devices
              .map(serialNumber => devices[serialNumber])
              .filter(
                  device =>
                      device &&
                      acceptedDeviceTypes.includes(device.type) &&
                      !selectedDevices.find(selected => selected.serialNumber === device.serialNumber)
              )
              .map(device => ({ id: device.serialNumber, name: device.segmentName }))
        : [];

    const sortedDevices: DeviceWithKeyInfo[] = selectedDevices.sort((deviceA, deviceB) =>
        deviceA.segmentName.localeCompare(deviceB.segmentName)
    );
    return (
        <>
            <div className="insight-tile__header insight-tile__header--padded insight-tile__header--no-border">
                <h2 id="device-header">
                    {acceptedDeviceTypes.length === 1
                        ? txt('Insight.DeviceInfoHeader', {
                              deviceType: txt(`${mapDeviceType(acceptedDeviceTypes[0])}FullName`),
                          })
                        : txt('RadonInsight.DeviceInfo')}
                </h2>
            </div>
            {building && selectSingleDevice !== true && (
                <div className="form__row">
                    <CheckBox
                        label={
                            acceptedDeviceTypes.length === 1
                                ? txt('Insight.AllDevicesOfTypeInBuilding', {
                                      building: building.name,
                                      deviceType: txt(`${acceptedDeviceTypes[0]}`),
                                  })
                                : txt('Insight.AllDevicesIn', { building: building.name })
                        }
                        id="checkAllDevices"
                        onChange={checkAllDevices}
                        checked={selectedDevices.length === allAcceptedDevices.length}
                        skipTranslate
                    />
                </div>
            )}
            <div className="form__row">
                <div className="form__field--standard-width">
                    <SearchDropdown listOfElements={locationDevices} onSelect={selectDevice} icon={<DeviceIcon />} />
                </div>
            </div>
            {sortedDevices.map(device => (
                <Fragment key={`selected-${device.serialNumber}`}>
                    <DeviceSelectorWithDetails
                        device={device}
                        removeDeviceFromList={removeDeviceFromList}
                        segmentProperties={segmentProperties[device.serialNumber]}
                        loading={false}
                    />
                    {lessThanRequiredDaysAgo(device.segmentStarted) && (
                        <div className="error-message error-message--with-icon">
                            <MaterialIcon name="error_outline" />
                            {txt('Insight.DeviceMeasuredForLessThan', { minDays: minDaysMeasured.toString() })}
                        </div>
                    )}
                </Fragment>
            ))}
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        locations: { error },
        devices: { devicesWithKeyInfo: devices },
        segmentPropertiesStore: { segmentProperties },
    } = state;
    return {
        devices,
        error,
        segmentProperties,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    getSegmentProperties: (serialNumber: string): FetchSegmentProperties =>
        dispatch(fetchSegmentProperties(serialNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceInsightComponent);
