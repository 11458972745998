import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Error from 'commons/src/components/errorComponents/Error';
import { fullwidthListElement } from 'commons/src/components/placeholder';
import { paths } from 'commons/src/constants';
import { HubData } from 'commons/src/models/commonTypeScript';
import { Store } from 'commons/src/reducers';
import HubInfo from './HubInfo';
import HubPageContent from './HubPageContent';
import HubPageHeaderComponent from './HubPageHeader';

type StateProps = {
    error: boolean;
    hubs: HubData[];
    loading: boolean;
};

export type Props = StateProps;

export const HubPageComponent = ({ error, hubs, loading }: Props): React.ReactElement => {
    const { hubId } = useParams() as { hubId: string };
    const navigate = useNavigate();
    const { t: txt } = useTranslation();

    if (error) {
        return <Error />;
    }

    const displayedHub = hubId ? hubs.find(hub => hub.serialNumber === hubId) : undefined;
    const invalidHub = !loading && !displayedHub;
    const hubRecentlySynced =
        displayedHub &&
        displayedHub.metaData.lastSeen &&
        moment.utc().diff(moment.utc(displayedHub.metaData.lastSeen).local(), 'minutes') < 65;
    const numberOfDevices =
        displayedHub && displayedHub.metaData.devices && hubRecentlySynced
            ? Object.keys(displayedHub.metaData.devices).length
            : 0;

    const onGoBack = (): void => navigate(`/${paths.devices}`);

    return (
        <div>
            <HubPageHeaderComponent
                displayedHub={displayedHub}
                invalidHub={invalidHub}
                onGoBack={onGoBack}
                onGoBackName={txt('AllDevices')}
            />
            <div className="container page-header--padded">
                <ReactPlaceholder ready={!loading} customPlaceholder={fullwidthListElement}>
                    <HubInfo hub={displayedHub} />
                </ReactPlaceholder>
                <div className="inline-header-lined">
                    <h2 className="inline-header-lined__text">
                        {txt('HubStrings.ConnectedDevices')}
                        <span className="inline-header-lined__info-chip">{numberOfDevices}</span>
                    </h2>
                </div>
            </div>
            <HubPageContent displayedHub={displayedHub} loading={loading} />
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        locations: { error, loading },
        devices: { hubs },
    } = state;
    return {
        error,
        hubs,
        loading,
    };
};

export default connect(mapStateToProps)(HubPageComponent);
