import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { analyticsLogger, PageType, FeatureType } from 'commons/src/analytics';
import {
    DEVICE_DOWNLOADED_QR,
    DEVICE_ENABLED_FEATURE,
    DEVICE_TOGGLED_PUBLIC,
} from 'commons/src/analytics/AnalyticsEvents';
import { publicQrUrl } from 'commons/src/commonFunctions';
import FeatureToggle from 'commons/src/components/buttons/FeatureToggle';
import QRLabel from 'commons/src/components/device/QRLabel';
import { IntercomAPI } from 'commons/src/components/Intercom';
import SpinnerBlocker from 'commons/src/components/modals/ModalSpinnerBlocker';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { publicDeviceShortUrl } from 'commons/src/constants';
import {
    DeviceConfig,
    DeviceWithKeyInfo,
    ErrorType,
    Group,
    SegmentProperties,
    Units,
} from 'commons/src/models/commonTypeScript';
import { GetOrganizationLogo, getOrganizationLogo } from '../../../actions/organizationPropertiesActions';
import {
    FetchGeneratedSegmentPublicUrlInit,
    fetchGeneratedSegmentPublicUrlInit,
    SetSegmentProperties,
    setSegmentProperties as setSegmentPropertiesAction,
} from '../../../actions/segmentPropertiesActions';
import { showMoldToggle, showPubliclyAvailableToggle, showVirusRiskToggle } from '../../../constants';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import GenerateQrLabel from './PDF/GenerateQrLabel';

const Spinner = SpinnerBlocker as React.FC<{ isLoading: boolean; children?: ReactElement }>;

interface StateProps {
    segmentProperties: {
        [serialNumber: string]: SegmentProperties;
    };
    fetchGeneratedSegmentPublicUrlLoading: boolean;
    selectedGroup?: Group;
    error?: ErrorType;
    urlError?: ErrorType;
    loadingLogo: boolean;
    logoImage?: string;
    language: string;
    units: Units;
    devices: Record<string, DeviceWithKeyInfo>;
    deviceConfigs: {
        [serialNumber: string]: DeviceConfig;
    };
}

interface FetchPublicUrlPayload {
    serialNumber: string;
}
type ParentProps = {
    serialNumber: string;
};

interface ActionProps {
    setSegmentProperties: (segmentProperties: SegmentProperties, serialNumber: string) => void;
    getGeneratedPublicUrl: (payload: FetchPublicUrlPayload) => void;
    getLogo: () => void;
}

export type Props = ActionProps & StateProps & ParentProps;
// this component will be hidden if the device does not return true for any of: showMoldToggle, showPubliclyAvailableToggle, showVirusRiskToggle
export const DeviceFeaturesComponent = (props: Props): React.ReactElement => {
    const {
        setSegmentProperties,
        segmentProperties,
        fetchGeneratedSegmentPublicUrlLoading,
        selectedGroup,
        getGeneratedPublicUrl,
        error,
        urlError,
        logoImage,
        loadingLogo,
        getLogo,
        language,
        devices,
        deviceConfigs,
        units,
        serialNumber,
    } = props;
    const device = devices[serialNumber];

    const { t: txt } = useTranslation();

    const { segmentName } = device;
    const deviceSegmentProps = segmentProperties[serialNumber];
    const deviceConfig = deviceConfigs[serialNumber];
    const [publiclyAvailable, setPubliclyAvailable] = useState(
        (deviceSegmentProps && deviceSegmentProps.publiclyAvailable) || false
    );
    const [moldEnabled, setMoldEnabled] = useState((deviceSegmentProps && deviceSegmentProps.moldEnabled) || false);
    const [virusRiskEnabled, setVirusRiskEnabled] = useState(
        (deviceSegmentProps && deviceSegmentProps.virusRiskEnabled) || false
    );
    const [qrImage, setQrImage] = useState<string>('');
    const publicUrlPath = segmentProperties[serialNumber].publicUrlPath || '';

    useEffect((): void => {
        if (!logoImage) {
            getLogo();
        }
    }, []);

    useEffect((): void => {
        if (!publiclyAvailable || (publiclyAvailable && publicUrlPath)) {
            setSegmentProperties(
                {
                    ...deviceSegmentProps,
                    publiclyAvailable,
                    moldEnabled,
                    virusRiskEnabled,
                    publicUrlPath,
                },
                serialNumber
            );
        }
    }, [publiclyAvailable, moldEnabled, publicUrlPath, virusRiskEnabled]);

    const qrUrl = publicQrUrl(publicUrlPath, language, units);
    const shortUrl = publicDeviceShortUrl.replace('{{publicUrlPath}}', publicUrlPath);

    const updateMoldState = (): void => {
        setMoldEnabled(!moldEnabled);
        analyticsLogger(DEVICE_ENABLED_FEATURE, {
            pageType: PageType.Device,
            featureType: FeatureType.MoldRisk,
            toggle: !moldEnabled,
        });
    };

    const updateVirusRiskState = (): void => {
        setVirusRiskEnabled(!virusRiskEnabled);
        analyticsLogger(DEVICE_ENABLED_FEATURE, {
            pageType: PageType.Device,
            featureType: FeatureType.VirusRisk,
            toggle: !virusRiskEnabled,
        });
    };

    useEffect((): void => {
        if (publiclyAvailable && !segmentProperties[serialNumber].publicUrlPath) {
            getGeneratedPublicUrl({ serialNumber });
        }
    }, [publiclyAvailable]);

    useEffect((): void => {
        if (fetchGeneratedSegmentPublicUrlLoading || publicUrlPath === '') return;
        const qrElement = document.getElementById('qrCode') as HTMLCanvasElement;
        if (qrElement) {
            const image = qrElement.toDataURL('img/png');
            setQrImage(image);
        }
    }, [fetchGeneratedSegmentPublicUrlLoading, loadingLogo]);

    const togglePublicSharing = (): void => {
        setPubliclyAvailable(!publiclyAvailable);
        analyticsLogger(DEVICE_TOGGLED_PUBLIC, { pageType: PageType.Device, toggle: publiclyAvailable });

        IntercomAPI('trackEvent', 'toggle-public-sharing', {
            publicToggle: !publiclyAvailable,
            serialNumber,
            qrUrl,
        });
    };

    const onQrDownload = (): void => {
        analyticsLogger(DEVICE_DOWNLOADED_QR, { pageType: PageType.Device, deviceType: device.type });
    };

    let errorText: string | undefined;
    const publicUrlOnScreen =
        deviceConfig && deviceConfig.selectedScreens.some(screen => screen.insights.includes('publicQr'));
    if (error) errorText = `ErrorCodes.${error.error}`;
    else if (urlError) errorText = `ErrorCodes.${urlError.error}`;
    else if (!publiclyAvailable && publicUrlOnScreen) errorText = 'DisplaySettings.PublicUrlDisplayedOnDevice';

    const organizationName = (selectedGroup && selectedGroup.organizationName) || '';

    return (
        <div className="page-wrapper__inner page-wrapper__inner--slim">
            <form className="change-location__form">
                {showMoldToggle(device.type) && (
                    <FeatureToggle
                        title="SegmentProperties.MoldRiskIndication"
                        description="SegmentProperties.MoldDescription"
                        enabled={moldEnabled}
                        onUpdate={updateMoldState}
                    />
                )}
                {showVirusRiskToggle(device.type) && (
                    <FeatureToggle
                        title="SegmentProperties.VirusRiskIndication"
                        description="SegmentProperties.VirusRiskDescription"
                        enabled={virusRiskEnabled}
                        onUpdate={updateVirusRiskState}
                    />
                )}
                {showPubliclyAvailableToggle(device.type) && (
                    <FeatureToggle
                        title="PublicIAQDashboard.PublicDevice"
                        description="PublicIAQDashboard.PublicDeviceDescription"
                        enabled={publiclyAvailable}
                        onUpdate={togglePublicSharing}
                    />
                )}
                {publiclyAvailable && (
                    <div className="segment-properties-form form__row--padded-small">
                        <Spinner
                            isLoading={fetchGeneratedSegmentPublicUrlLoading || publicUrlPath === '' || loadingLogo}
                        >
                            <div className="form__qr-container">
                                <QRLabel url={qrUrl} shortUrl={shortUrl} />
                                <div className="form__row form__row--spaced">
                                    <div className="form__row--padded-small no-margin--side">
                                        <span className="form__attr">{txt('PublicIAQDashboard.Url')}</span>
                                        <a
                                            href={qrUrl}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className="form__attr"
                                        >
                                            {shortUrl}
                                        </a>
                                    </div>
                                    {qrImage && (
                                        <div
                                            role="button"
                                            className="no-margin"
                                            onClick={onQrDownload}
                                            onKeyUp={onQrDownload}
                                            tabIndex={0}
                                        >
                                            <GenerateQrLabel
                                                serialNumber={serialNumber}
                                                deviceName={segmentName}
                                                organizationName={organizationName}
                                                qrImage={qrImage}
                                                url={shortUrl}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Spinner>
                    </div>
                )}
                {!!errorText && <ResponseBox text={errorText} />}
            </form>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        segmentPropertiesStore: { segmentProperties },
        deviceConfig: { deviceConfigs },
        devices: { devicesWithKeyInfo: devices },
        requests: {
            [RequestType.FetchSegmentProperties]: { error },
            [RequestType.FetchGeneratedSegmentPublicUrl]: {
                loading: fetchGeneratedSegmentPublicUrlLoading,
                error: urlError,
            },
            [RequestType.GetOrganizationLogo]: { loading: loadingLogo },
        },
        userSettings: { selectedGroup, language, units },
        organizationProperties: { logoImage },
    } = store;

    return {
        segmentProperties,
        fetchGeneratedSegmentPublicUrlLoading,
        selectedGroup,
        error,
        loadingLogo,
        urlError,
        logoImage,
        language,
        devices,
        deviceConfigs,
        units,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    setSegmentProperties: (segmentProperties: SegmentProperties, serialNumber: string): SetSegmentProperties =>
        dispatch(setSegmentPropertiesAction(segmentProperties, serialNumber)),
    getGeneratedPublicUrl: (payload: FetchPublicUrlPayload): FetchGeneratedSegmentPublicUrlInit =>
        dispatch(fetchGeneratedSegmentPublicUrlInit(payload)),
    getLogo: (): GetOrganizationLogo => dispatch(getOrganizationLogo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceFeaturesComponent);
