import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/react';
import { getSelectedGroupFromStorage } from '../components/findUserType';
import config from '../config';
import { ContentTypes } from '../constants';
import { GroupType } from '../models/commonEnums';
import { ErrorType, SelectedGroup } from '../models/commonTypeScript';

export interface UrlWithQueryParams {
    path?: string;
    [extraParams: string]: string | undefined;
}

export const urlWithQueryParams = ({ path, ...extraParams }: UrlWithQueryParams): string => {
    const searchParams = new URLSearchParams();
    Object.keys(extraParams)
        .filter(p => !!extraParams[p])
        .map(p => searchParams.append(p, <string>extraParams[p]));
    const queryString = searchParams.toString();
    return `${path}?${queryString}`;
};

const unauthorizedCause = 'TIMED_OUT_SESSION?';

/**
 * Sentry won't report on Slack unless the severity is high enough (Error),
 * and some errors is ignorable. This function will override the severity based on
 * error type.
 */
const getLevelForError = (errorCode: string): SeverityLevel => {
    switch (errorCode) {
        case 'NOT_FOUND':
        case 'DEVICE_INVALID_CHECK_CODE':
        case 'DEVICE_ALREADY_REGISTERED':
        case 'DEVICE_ALREADY_REGISTERED_TO_ORGANIZATION':
        case 'UNAUTHORIZED_CLIENT':
        case unauthorizedCause:
        case 'INVALID_REQUEST_USER_CHANGE_PASSWORD_INVALID_OLD_PASSWORD':
        case 'INVALID_REQUEST_CLIENTS_LIMIT_EXCEEDED':
            return 'warning';
        default:
            return 'error';
    }
};

const parseErrorCode = (response?: Response, error?: ErrorType): string =>
    (error && error.error) || (response && response.status === 401 ? unauthorizedCause : 'unknown error');

const writeToSentry = (error: ErrorType, response: Response, url?: string | URL): void => {
    if (Sentry) {
        const userGroupId = getSelectedGroupFromStorage()?.userGroupId;
        const errorCode = parseErrorCode(response, error);
        Sentry.captureEvent({
            message: `fetch received error response - ${errorCode}`,
            extra: { response, error, error_message: error.message },
            level: getLevelForError(errorCode),
            tags: {
                ...error,
                statusCode: String(response.status),
                url: url ? url.toString() : 'unknown',
                userGroupId,
            },
        });
    }
};

export const statusHelper = <T>(response: Response, url?: string | URL): Promise<T> => {
    if (response.status === 204) {
        return Promise.resolve({} as Promise<T>);
    }

    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json() as Promise<T>);
    }
    if (response.status === 401) {
        // try to refresh token if not valid
        // logoutAndClearStorage();
    }

    return response.json().then(json => {
        if (response.status !== 401 && response.status !== 403) {
            writeToSentry(json, response, url);
        }
        return Promise.reject(json);
    });
};

export const customUrlCustomFetch = <T>(url: URL, options: RequestInit): Promise<T> => {
    const selectedGroup: SelectedGroup | null = getSelectedGroupFromStorage();

    if (selectedGroup) {
        url.searchParams.append('userGroupId', selectedGroup.userGroupId);
    }
    url.searchParams.append('groupType', selectedGroup?.groupType || GroupType.consumer);

    const headers: HeadersInit = {
        Accept: ContentTypes.JSON,
        'Content-Type': ContentTypes.JSON,
        ...options.headers,
    };

    if (
        options.headers &&
        (options.headers as Record<string, string>)['Content-Type']?.includes('multipart/form-data')
    ) {
        delete (headers as Record<string, string>)['Content-Type'];
    }

    return fetch(url.href, {
        ...options,
        headers,
    }).then((response: Response) => statusHelper<T>(response, url));
};

export const fetchWithoutOrgId = <T>(url: URL, options: RequestInit, acceptContentType?: string): Promise<T> => {
    const headers: HeadersInit = {
        Accept: acceptContentType || ContentTypes.JSON,
        'Content-Type': ContentTypes.JSON,
        ...options.headers,
    };
    return fetch(url.href, {
        ...options,
        headers,
    }).then((response: Response) => statusHelper<T>(response, url));
};

export const fetchOutsideAirthings = <T>(url: string, options: RequestInit, acceptContentType?: string): Promise<T> => {
    const headers: HeadersInit = {
        Accept: acceptContentType || ContentTypes.JSON,
        'Content-Type': ContentTypes.JSON,
        ...options.headers,
    };
    return fetch(url, {
        ...options,
        headers,
    }).then(response => statusHelper<T>(response, url));
};

export const fetchFromAccounts = <T>(url: string, options: RequestInit): Promise<T> => {
    const finalUrl: URL = new URL(`${config.accountsApiUrl}${url}`);
    return customUrlCustomFetch<T>(finalUrl, options);
};

export const fetchFromOldDashboardApi = <T>(url: string, options: RequestInit): Promise<T> => {
    const finalUrl: URL = new URL(`${config.apiUrl}${url}`);
    return customUrlCustomFetch<T>(finalUrl, options);
};

const fetchFromDashboardApi = <T>(url: string, options: RequestInit): Promise<T> => {
    const finalUrl: URL = new URL(`${config.newApiUrl}${url}`);
    return customUrlCustomFetch<T>(finalUrl, options);
};

export const fetchFromDashboardPublicApi = <T>(url: string, options: RequestInit): Promise<T> => {
    const finalUrl: URL = new URL(`${config.publicApiUrl}${url}`);
    return customUrlCustomFetch<T>(finalUrl, options);
};

export const offlineError = (error: ErrorType): boolean =>
    error && error.error === 'TypeError' && error.message === 'Failed to fetch';

export default fetchFromDashboardApi;
