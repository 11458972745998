import React, { useMemo } from 'react';
import classNames from 'classnames';
import L, { DivIcon, LatLng, LatLngExpression } from 'leaflet';
import { Marker, Polygon } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { deviceStatusColor } from 'commons/src/commonFunctions';
import { EnumStatusColors, DeviceAttributes } from 'commons/src/models/commonEnums';
import { CurrentSensorValuesType, FloorPlanPosition } from 'commons/src/models/commonTypeScript';
import DeviceHealthStatus from 'commons/src/models/deviceHealthStatus';
import { SpaceFloorPlanMode } from '../../../models/spaceFloorPlanModels';
import { IndoorSpace, SpaceDevice, SpaceHub } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import { spacesSelector } from '../../spaces/space/spaceSelectors';
import MarkerIcons from './MarkerIcons';
import styles from './SpaceZone.module.scss';

type Props = {
    zone: {
        id: string;
        boundary: FloorPlanPosition[];
    };
    selectedSpaceId: string | undefined;
    mode?: SpaceFloorPlanMode;
    selectedSensor?: string;
    locationId: string;
    setMode: (mode: SpaceFloorPlanMode | undefined) => void;
    setSelectedSpace: (spaceId: string) => void;
};

const SpaceZone = ({
    zone,
    selectedSpaceId,
    mode,
    selectedSensor,
    locationId,
    setMode,
    setSelectedSpace,
}: Props): React.ReactElement => {
    const zonePositions: LatLngExpression[] = useMemo(
        () => zone.boundary.map(({ lat, lng }) => [lat, lng] as [number, number]),
        [zone.boundary]
    );
    const isSelected = selectedSpaceId === zone.id;

    const clickHandler = (): void => {
        if (!mode) {
            setMode(SpaceFloorPlanMode.INSPECT);
            setSelectedSpace(zone.id);
        } else if (mode === SpaceFloorPlanMode.INSPECT) {
            setMode(selectedSpaceId === zone.id ? undefined : SpaceFloorPlanMode.INSPECT);
            setSelectedSpace(zone.id);
        }
    };

    const { spaces } = useSelector((store: Store) => spacesSelector(store, locationId)).spaces;

    const space = useMemo(() => spaces.find(it => it.id === zone.id) as IndoorSpace | undefined, [spaces, zone.id]);
    if (!space || (selectedSpaceId === zone.id && mode === SpaceFloorPlanMode.EDIT)) return <div />;

    const markerOffsets = (numDevices: number): { lat: number; lng: number }[] => {
        const spacing = 16;
        return Array.from({ length: numDevices }, (_, i) => ({
            lat: ((numDevices - 1) / 2) * spacing - i * spacing,
            lng: 0,
        }));
    };

    const getMarkerPosition = (index: number): { lat: number; lng: number } => {
        const centerPosition: LatLng = L.polygon(zonePositions).getBounds().getCenter();
        const offset = markerOffsets(space.devices.length)[index];
        return { lat: centerPosition.lat + offset.lat, lng: centerPosition.lng + offset.lng };
    };

    const hubOffsets = (numHubs: number, numDevices: number): { lat: number; lng: number }[] => {
        const deviceSpacing = 16;
        const hubSpacing = 16;
        const deviceOffset = numDevices * deviceSpacing;
        return Array.from({ length: numHubs }, (_, i) => ({
            lat: -deviceOffset - ((numHubs - 1) / 2) * hubSpacing + i * hubSpacing,
            lng: 0,
        }));
    };

    const getHubMarkerPosition = (deviceCount: number, hubIndex: number): { lng: number; lat: number } => {
        const centerPosition: LatLng = L.polygon(zonePositions).getBounds().getCenter();
        const offset = hubOffsets(space.hubs?.length || 0, deviceCount)[hubIndex];
        return { lat: centerPosition.lat + offset.lat, lng: centerPosition.lng + offset.lng };
    };

    const getSensorValue = (device: SpaceDevice): number | undefined =>
        device.currentSensorValues.find(sensor => sensor.type === selectedSensor)?.value;

    const allDevicesAreOffline: boolean = space.devices
        .flatMap(device => device.healthStatus)
        .every(it => [DeviceHealthStatus.offline, DeviceHealthStatus.notSynced].includes(it));

    const getMarkerIcon = (device: SpaceDevice): DivIcon => {
        if (allDevicesAreOffline) return MarkerIcons.Offline;
        if (selectedSensor === DeviceAttributes.Battery) return MarkerIcons.Battery(device.batteryPercentage);
        if (selectedSensor === DeviceAttributes.Connection) return MarkerIcons.Rssi(device.rssi);
        return MarkerIcons.SensorValue(getSensorValue(device));
    };

    const getHubIcon = (hub: SpaceHub): DivIcon => {
        if (hub.healthStatus === DeviceHealthStatus.offline) return MarkerIcons.HubOffline;
        if (hub.healthStatus === DeviceHealthStatus.notSynced) return MarkerIcons.HubNotSync;
        return MarkerIcons.HubOnline;
    };

    const allSensorValuesInSpace: CurrentSensorValuesType[] = space.devices
        .flatMap(it => it.currentSensorValues)
        .filter(it => it.type === selectedSensor);
    const statusColor: EnumStatusColors = selectedSensor
        ? deviceStatusColor([selectedSensor], allDevicesAreOffline, allSensorValuesInSpace)
        : EnumStatusColors.grey;

    return (
        <Polygon
            key={`${zone.id}-polygon-${statusColor}-${isSelected}`}
            positions={zonePositions}
            className={classNames(styles.zone, {
                [styles[`polygon-${statusColor}`]]: !isSelected,
                [styles[`polygon-${statusColor}-selected`]]: isSelected,
            })}
            pathOptions={{ weight: isSelected ? 3 : 1 }}
            eventHandlers={{ click: clickHandler }}
        >
            {space.devices.map((device, index) => (
                <Marker
                    key={device.serialNumber}
                    position={getMarkerPosition(index)}
                    icon={getMarkerIcon(device)}
                    eventHandlers={{ click: clickHandler }}
                />
            ))}
            {space.hubs?.map((hub, index) => (
                <Marker
                    key={hub.serialNumber}
                    position={getHubMarkerPosition(space.devices.length, index)}
                    icon={getHubIcon(hub)}
                    eventHandlers={{ click: clickHandler }}
                />
            ))}
        </Polygon>
    );
};

export default SpaceZone;
