import { oldSubscriptionDevices, subscriptionDevices } from 'commons/src/DeviceAndSensorLists';
import { BillingType, ServicePeriod } from '../../../../models/commonEnums';
import {
    ProductRatePlanCharge,
    ProductRatePlanCharges,
    RatePlans,
    RatePlansPerPeriod,
    SubscriptionSeats,
} from '../../../../models/subscriptionModels';

export const getSeatsPerDevice = (
    deployedDevices: { [device: string]: number },
    ratePlans: RatePlans
): SubscriptionSeats[] => {
    const ratePlanDevices = Object.keys(ratePlans);
    return (
        subscriptionDevices
            .filter(deviceType => ratePlanDevices.includes(deviceType))
            .map(device => {
                const currentDeployedSeats = deployedDevices[device] || 0;
                return { deviceType: device, deployedSeats: currentDeployedSeats, seats: 0 };
            }) || []
    );
};

export const getDeviceAndSeatCount = (
    deployedDevices: { [device: string]: number },
    seats: { [device: string]: number }
): { deviceType: string; count: number; seatCount: number }[] => {
    const oldDevices = oldSubscriptionDevices
        .map(device => {
            const currentDeployedSeats = deployedDevices[device] || 0;
            const seatsPayedFor = seats[device] || 0;
            return { deviceType: device, count: currentDeployedSeats, seatCount: seatsPayedFor };
        })
        .filter(device => device.count > 0 || device.seatCount > 0);

    const possibleSubscriptionDevices =
        subscriptionDevices.map(device => {
            const currentDeployedSeats = deployedDevices[device] || 0;
            const seatsPayedFor = seats[device] || 0;
            return { deviceType: device, count: currentDeployedSeats, seatCount: seatsPayedFor };
        }) || [];

    return [...possibleSubscriptionDevices, ...oldDevices];
};

export const getTermPeriod = (fromServicePeriod: ServicePeriod): number => {
    switch (fromServicePeriod) {
        case ServicePeriod.OneYear:
            return 12;
        case ServicePeriod.ThreeYears:
            return 12 * 3;
        case ServicePeriod.FiveYears:
            return 12 * 5;
        default:
            return 0;
    }
};

export const getServicePeriodName = (servicePeriodInYear: number): ServicePeriod | undefined => {
    switch (servicePeriodInYear) {
        case 1:
            return ServicePeriod.OneYear;
        case 3:
            return ServicePeriod.ThreeYears;
        case 5:
            return ServicePeriod.FiveYears;
        default:
            return undefined;
    }
};

export const getRatePlansForSelection = (
    ratePlans: RatePlansPerPeriod,
    servicePeriod: ServicePeriod,
    seatsPerDevice: SubscriptionSeats[]
): ProductRatePlanCharges[] => {
    return seatsPerDevice
        .map(device => {
            const productRatePlans = ratePlans[servicePeriod]?.products[device.deviceType];
            const ratePlan = productRatePlans?.filter(plan => plan.billingType === BillingType.FULL)[0];
            const discountChargeId = {
                productRatePlanChargeId: ratePlan?.productDiscount?.productRatePlanChargeId,
            };
            const productRatePlanPrice: ProductRatePlanCharge = {
                productRatePlanChargeId:
                    device.productRatePlanChargeId || ratePlan?.productPrice.productRatePlanChargeId,
                quantity: device.seats,
            };

            if (device.chargeNumber) {
                productRatePlanPrice.chargeNumber = device.chargeNumber;
            }
            const productRatePlanCharges: ProductRatePlanCharge[] =
                device.chargeNumber || !ratePlan?.productDiscount
                    ? [productRatePlanPrice]
                    : [productRatePlanPrice, discountChargeId];

            return {
                productRatePlanId: device.productRatePlanId || ratePlan?.productRatePlanId,
                ratePlanId: device.ratePlanId,
                productRatePlanCharges,
            };
        })
        .filter(plan => {
            const planWithDevices = plan.productRatePlanCharges.find(charge => !!charge.quantity);
            return !!plan.productRatePlanCharges && plan.productRatePlanCharges.length > 0 && planWithDevices;
        });
};
