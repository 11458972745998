import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getValidLanguageTagForMoment, lastSyncedDate, mapDeviceType } from 'commons/src/commonFunctions';
import BatteryIcon from 'commons/src/components/device/BatteryIcon';
import DeviceIcon from 'commons/src/components/device/DeviceIcon';
import RssiSignalStrength from 'commons/src/components/sensors/RssiSignalStrength';
import { dateFormats } from 'commons/src/constants';
import { AnyDeviceType, DeviceType } from 'commons/src/models/commonTypeScript';
import { Store } from 'commons/src/reducers';

export type ParentProps = {
    device: DeviceType;
};

type StateProps = {
    dateFormat: string;
};

type Props = ParentProps & StateProps;

export const DeviceInfoTileComponent = ({ device, dateFormat }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const language = getValidLanguageTagForMoment();
    const deviceRecentlySynced =
        device.latestSample && moment.utc().diff(moment.utc(device.latestSample).local(), 'hours') < 1;

    const lastSynced = device.latestSample
        ? moment.utc(device.latestSample).local().locale(language).fromNow()
        : txt('neverSynced');

    return (
        <NavLink to={`/devices/${device.serialNumber}`} className="building-tile__link page-wrapper__column" data-tile>
            <div className="building-tile hub-device-tile">
                <div className="hub-device-tile__device-image">
                    <DeviceIcon deviceType={mapDeviceType(device.type as AnyDeviceType)} />
                </div>
                <div className="hub-device-tile__content">
                    <h2 className="hub-device-tile__content__header">{device.segmentName}</h2>

                    <div
                        className={classNames('hub-device-tile__content__text', {
                            'hub-device-tile__content__text--alert': !deviceRecentlySynced,
                        })}
                    >
                        {deviceRecentlySynced ? (
                            [txt('LastSynced'), ' ', lastSynced]
                        ) : (
                            <>
                                <div className="sensor__line sensor__line--alert" />
                                {txt('LastSynced')}{' '}
                                {lastSyncedDate(device.latestSample, dateFormat as keyof typeof dateFormats)}
                            </>
                        )}
                    </div>
                    {deviceRecentlySynced && (
                        <div className="info-header__content">
                            {device.batteryPercentage !== undefined && device.batteryPercentage >= 0 && (
                                <div className="info-header__content__spesifications">
                                    <BatteryIcon batteryPercentage={device.batteryPercentage || 20} />
                                    <div>
                                        {device.batteryPercentage}
                                        {'% '}
                                    </div>
                                </div>
                            )}
                            {!Number.isNaN(Number(device.rssi)) && (
                                <div className="info-header__content__spesifications">
                                    <RssiSignalStrength
                                        inverted={false}
                                        rssi={device.rssi}
                                        displayDescription={false}
                                    />
                                    <div>{[device.rssi, ' dBm']}</div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </NavLink>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
    } = state;
    return {
        dateFormat,
    };
};

export default connect(mapStateToProps)(DeviceInfoTileComponent);
