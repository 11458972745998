import React, { ReactElement, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { limitSubscription } from 'commons/src/commonFunctions';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import PageWrapper from 'commons/src/components/containers/PageWrapper';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { fullWidthListPlaceholder } from 'commons/src/components/placeholder';
import { customPeriodName, paths, sensorGraphPeriods } from 'commons/src/constants';
import { DeviceTypeNames, SensorTypes } from 'commons/src/models/commonEnums';
import { SelectedPeriod } from 'commons/src/models/commonTypeScript';
import { fetchSpaceAggregationDigest } from '../../../actions/eventAggregationActions';
import { fetchOutdoorData } from '../../../actions/outdoorDataActions';
import { fetchSpaces, fetchSpaceSensorData, fetchSpaceVirtualSensorData } from '../../../actions/spaceActions';
import { IndoorSpace, SpaceDevice } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import SpaceDevicesSection from './devices/SpaceDevicesSection';
import SpaceGraphSection from './graphs/SpaceGraphSection';
import LimitedSpaceGraph from './LimitedSpaceGraph';
import styles from './SpacePage.module.scss';
import { spaceSelector, spaceSensorData, spacesSelector } from './spaceSelectors';

dayjs.extend(duration);

const SpacePage = (): ReactElement => {
    const { buildingId = '', spaceId = '' } = useParams();
    const dispatch = useDispatch();
    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    const [fetchingSpaces, setFetchingSpaces] = useState(true);
    const { outdoorData, locationId: outdoorLocationId } = useSelector((state: Store) => state.outdoorData);
    const enableEventAggregationToggleOn = useSelector((state: Store) => state.toggles.enableEventAggregationToggleOn);

    const fetchOutdoorSensorData = (period: SelectedPeriod): void => {
        const measurementsInStateForPeriod = outdoorData[period.name]?.[SensorTypes.temp]?.measurements;
        const latestTimestampForPeriod = measurementsInStateForPeriod?.[measurementsInStateForPeriod.length - 1]?.[0];
        const fetchIfBeforeTime = period.resolution
            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              dayjs.utc().subtract(period.resolutionDuration)
            : dayjs.utc().subtract(1, 'hour');

        const lastDataOlderThanResolutionDuration = dayjs.utc(latestTimestampForPeriod).isBefore(fetchIfBeforeTime);
        if (
            outdoorLocationId !== buildingId ||
            period.name === customPeriodName ||
            !latestTimestampForPeriod ||
            lastDataOlderThanResolutionDuration
        ) {
            dispatch(fetchOutdoorData(buildingId, period));
        }
    };

    const fetchDataForPeriod = (period: SelectedPeriod): void => {
        dispatch(fetchSpaceSensorData(buildingId, spaceId, period));
        dispatch(fetchSpaceVirtualSensorData(buildingId, spaceId, period));
        fetchOutdoorSensorData(period);
    };

    const fetchDigestForPeriod = (period: SelectedPeriod): void => {
        if (enableEventAggregationToggleOn) {
            dispatch(fetchSpaceAggregationDigest(spaceId, period));
        }
    };

    const { subscriptionForGroup } = useSelector((state: Store) => state.userSettings);
    const subscriptionIsLimited = limitSubscription(subscriptionForGroup);
    const {
        spaces: { spaces },
        request: { loading, error },
    } = useSelector((state: Store) => spacesSelector(state, buildingId));
    const { space } = useSelector((state: Store) => spaceSelector(state, buildingId, spaceId));
    const {
        spaceData: { deviceData },
    } = useSelector((state: Store) => spaceSensorData(state, spaceId));

    const sensorDevicesInSpace = (space as IndoorSpace)?.devices
        .filter(device => device.deviceType !== DeviceTypeNames.hub)
        .map(device => device as SpaceDevice);
    const devicesInSpace = sensorDevicesInSpace?.length > 0 || (deviceData && Object.keys(deviceData).length > 0);

    useEffect(() => {
        if (buildingId && spaces.length === 0) {
            dispatch(fetchSpaces(buildingId));
        } else {
            setFetchingSpaces(false);
        }
    }, [buildingId]);

    useEffect(() => {
        if (buildingId && spaceId && !subscriptionIsLimited) {
            fetchDataForPeriod(sensorGraphPeriods.week);
            fetchDigestForPeriod(sensorGraphPeriods.week);
        }
    }, [buildingId, subscriptionIsLimited]);

    const navToAllSpaces = (): void => {
        navigate(`/${paths.buildings}/${buildingId}/spaces`);
    };

    useEffect(() => {
        if (!loading && !space && !error && !fetchingSpaces) {
            navToAllSpaces();
        }
        if (!loading && fetchingSpaces) {
            setFetchingSpaces(false);
        }
    }, [loading, error]);

    return (
        <PageWrapper pageType="wide">
            <div className={styles.backButton}>
                <TertiaryButton
                    onClick={navToAllSpaces}
                    title={txt('Spaces.AllSpaces')}
                    icon={<MaterialIcon name="chevron_left" />}
                    testId="nav-all-spaces"
                />
            </div>
            <ReactPlaceholder
                showLoadingAnimation
                ready={(!fetchingSpaces && !loading) || !!space}
                customPlaceholder={fullWidthListPlaceholder}
            >
                {space && <SpaceDevicesSection space={space as IndoorSpace} />}
            </ReactPlaceholder>
            {subscriptionIsLimited ? <LimitedSpaceGraph /> : <div />}
            {!subscriptionIsLimited && devicesInSpace ? (
                <SpaceGraphSection
                    spaceId={spaceId}
                    locationId={buildingId}
                    fetchDataForPeriod={fetchDataForPeriod}
                    fetchDigestForPeriod={fetchDigestForPeriod}
                />
            ) : (
                <div />
            )}
        </PageWrapper>
    );
};
export default SpacePage;
