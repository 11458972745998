import { createSelector } from '@reduxjs/toolkit';
import { airthingsSensorDevices } from 'commons/src/DeviceAndSensorLists';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import DeviceHealthStatus from 'commons/src/models/deviceHealthStatus';
import { RequestState } from 'commons/src/reducers/requestReducer';
import { BuildingHealthRowData } from '../../../models/buildingHealthModels';
import { Store } from '../../../reducers';

export type SelectAllDevices = {
    devices: BuildingHealthRowData[];
    offlineDevices: BuildingHealthRowData[];
    onlineDevices: DeviceWithKeyInfo[];
    request: RequestState;
};

const offlineTypes = [DeviceHealthStatus.offline, DeviceHealthStatus.notSynced];

const selectAllDevices = (state: Store, locationId?: string): BuildingHealthRowData[] => {
    if (locationId) {
        return state.buildingsHealth[locationId]?.devices ?? [];
    }
    return [];
};

const selectDeviceWithKeyInfo = (state: Store): { [serialNumber: string]: DeviceWithKeyInfo } => {
    return state.devices.devicesWithKeyInfo;
};
const selectAllDevicesRequest = (state: Store): RequestState => state.requests.FETCH_BUILLDING_HEALTH_DEVICES_STATS;

const selectDevicesKeyInfoRequest = (state: Store): RequestState => state.requests.FETCH_BUILDING_WITH_DEVICES;

export const allDevicesSelector = createSelector(
    [selectAllDevices, selectAllDevicesRequest, selectDeviceWithKeyInfo, selectDevicesKeyInfoRequest],
    (devices, request, devicesInfo, deviceInfoRequest): SelectAllDevices => {
        const offlineDevices = devices.filter(
            device => device.healthStatus && offlineTypes.includes(device.healthStatus)
        );

        const onlineDevices = devices
            .filter(
                device =>
                    device.healthStatus &&
                    !offlineTypes.includes(device.healthStatus) &&
                    airthingsSensorDevices.includes(device.type as DeviceTypeNames)
            )
            .map(device => devicesInfo[device.serialNumber])
            .filter(Boolean);

        const combinedRequest: RequestState = {
            loading: request.loading || deviceInfoRequest.loading,
            error: request.error || deviceInfoRequest.error,
        };

        return {
            devices,
            offlineDevices,
            onlineDevices,
            request: combinedRequest,
        };
    }
);
