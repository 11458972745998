import { Store as CommonStore } from 'commons/src/reducers/index';
import { RequestState } from 'commons/src/reducers/requestReducer';
import buildingsHealth, { BuildingsHealthStatusState } from './buildingHealthReducer';
import buildingOptimization, { BuildingOptimizationState } from './buildingOptimizationReducer';
import buildings, { BuildingsState } from './Buildings';
import buildingsOverview, { BuildingsOverviewState } from './buildingsOverviewReducer';
import { BusinessRequestType as RequestType } from './BusinessRequestType';
import eventAggregation, { EventAggregationStore } from './eventAggregationReducer';
import buildingsHealthStatus, { HealthStatusState } from './healthStatusReducer';
import iaqInsight, { IaqInsightState } from './IaqInsightReducer';
import mqtt, { MqttState } from './mqttReducer';
import organizationHealth, { OrganizationHealthStatusState } from './organizationHealthReducer';
import organizationProperties, { OrganizationPropertiesStore } from './organizationPropertiesReducer';
import settings, { OrganizationStore } from './organizationReducer';
import outdoorData, { OutdoorDataStore } from './outdoorDataReducer';
import outdoorInsight, { OutdoorInsightState } from './outdoorInsightReducer';
import partner, { PartnerState } from './Partner';
import radonInsight, { RadonInsightState } from './radonInsightReportReducer';
import requests, { BusinessRequestStore } from './requestReducer';
import segmentPropertiesStore, { SegmentPropertiesStore } from './segmentPropertiesReducer';
import spaceData, { SpaceDataStore } from './spaceDataReducer';
import floorPlansSpace, { SpaceFloorPlanState } from './spaceFloorPlanReducer';
import spaces, { SpaceStore } from './spaceReducer';
import subscription, { SubscriptionStore } from './subscriptionReducer';
import thirdPartyIntegrations, { ThirdPartyIntegrationsState } from './thirdPartyIntegrationsReducer';
import thresholdBreachInsight, { ThresholdBreachInsightState } from './thresholdBreachInsightReducer';
import virusRiskInsight, { VirusRiskInsightState } from './virusRiskInsightReducer';
import webhooks, { WebhooksState } from './webhookReducer';

export interface Store extends CommonStore {
    buildingOptimization: BuildingOptimizationState;
    buildings: BuildingsState;
    buildingsHealthStatus: HealthStatusState;
    buildingsOverview: BuildingsOverviewState;
    floorPlansSpace: SpaceFloorPlanState;
    iaqInsight: IaqInsightState;
    mqtt: MqttState;
    organizationProperties: OrganizationPropertiesStore;
    outdoorData: OutdoorDataStore;
    outdoorInsight: OutdoorInsightState;
    partner: PartnerState;
    radonInsight: RadonInsightState;
    requests: BusinessRequestStore;
    segmentPropertiesStore: SegmentPropertiesStore;
    settings: OrganizationStore;
    spaces: SpaceStore;
    spaceData: SpaceDataStore;
    eventAggregation: EventAggregationStore;
    subscription: SubscriptionStore;
    thirdPartyIntegrations: ThirdPartyIntegrationsState;
    thresholdBreachInsight: ThresholdBreachInsightState;
    virusRiskInsight: VirusRiskInsightState;
    webhooks: WebhooksState;
    buildingsHealth: BuildingsHealthStatusState;
    organizationHealth: OrganizationHealthStatusState;
}

const rootReducer = {
    buildingOptimization,
    buildings,
    buildingsHealth,
    buildingsHealthStatus,
    buildingsOverview,
    floorPlansSpace,
    iaqInsight,
    mqtt,
    organizationHealth,
    organizationProperties,
    outdoorData,
    outdoorInsight,
    partner,
    radonInsight,
    requests,
    segmentPropertiesStore,
    settings,
    spaceData,
    spaces,
    eventAggregation,
    subscription,
    thirdPartyIntegrations,
    thresholdBreachInsight,
    virusRiskInsight,
    webhooks,
};

export const getRequestsSelector = (store: Store, requestType: RequestType): RequestState => {
    return store.requests[requestType];
};
export default rootReducer;
