import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { PlacementType } from 'commons/src/models/commonEnums';
import { SpaceProperty } from 'commons/src/models/commonTypeScript';
import { CreateSpacePayload } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import { RootState } from '../../../store';
import SpacePropertyEditMain from '../properties/SpacePropertyEditMain';
import styles from './SpaceAddModal.module.scss';
import SpaceSettingsForm, { FormActions } from './SpaceSettingsForm';

export type Props = {
    onClose: () => void;
    locationId: string;
    addSingleSpace?: boolean;
    withDescription?: boolean;
    onSave: (payload: CreateSpacePayload, redirectToSpace?: boolean) => void;
    isLoading: boolean;
    setIsLoading?: (param: boolean) => void;
    setEnableHeader?: (param: boolean) => void;
    floorNumber?: number;
};

const SpaceAddForm = ({
    onClose,
    locationId,
    addSingleSpace,
    onSave,
    isLoading,
    setIsLoading,
    setEnableHeader,
    withDescription,
    floorNumber,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const formRef = useRef<FormActions>(null);

    const { loading, error } = useSelector((state: RootState) => state.requests[RequestType.CreateSpace]);
    const [addAnother, setAddAnother] = useState(false);

    const [selectedProperty, setSelectedProperty] = useState<{ id: string; data: SpaceProperty } | undefined>(
        undefined
    );

    const spacePropDefs: { [p: string]: SpaceProperty } = useSelector((state: Store) => state.config.spacePropDefs);

    useEffect(() => {
        const property: [string, SpaceProperty] | undefined = Object.entries(spacePropDefs).find(
            ([key]) => key === selectedProperty?.id
        );
        setSelectedProperty(property ? { id: property[0], data: property[1] } : undefined);
        if (property && setEnableHeader) {
            setEnableHeader(false);
        }
    }, [spacePropDefs]);

    useEffect(() => {
        if (!loading && isLoading) {
            if (setIsLoading) {
                setIsLoading(false);
            }
            setAddAnother(false);
            if (!error) {
                formRef.current?.Clear();
                if (!addAnother) {
                    onClose();
                }
            }
        }
    }, [loading]);

    const onClickToSave = (redirectToSpace: boolean): void => {
        if (formRef.current && formRef.current.IsValid()) {
            const { name, properties } = formRef.current.Values();
            onSave(
                {
                    name,
                    properties,
                    placement: PlacementType.ROOM,
                },
                redirectToSpace
            );
        }
    };

    const saveAndAddAnother = (): void => {
        setAddAnother(true);
        onClickToSave(false);
    };

    const editProperty = (propertyId: string): void => {
        const property: [string, SpaceProperty] | undefined = Object.entries(spacePropDefs).find(
            ([key]) => key === propertyId
        );
        setSelectedProperty(property ? { id: property[0], data: property[1] } : undefined);
        if (property && setEnableHeader) {
            setEnableHeader(false);
        }
    };

    const onGoBackToSpace = (): void => {
        setSelectedProperty(undefined);
        if (setEnableHeader) {
            setEnableHeader(true);
        }
    };

    if (selectedProperty) {
        return (
            <SpacePropertyEditMain
                backToSpace={onGoBackToSpace}
                property={selectedProperty}
                allPropertyNames={Object.values(spacePropDefs).map(it => it.propertyName)}
            />
        );
    }

    return (
        <div className={styles.body} data-add-space-modal>
            {withDescription !== false && <p>{txt('AddSpace.AddSpaceDescription')}</p>}
            <SpaceSettingsForm
                ref={formRef}
                locationId={locationId}
                editProperty={editProperty}
                floorNumber={floorNumber}
            />
            <div className={styles.buttonWrapper}>
                {addSingleSpace !== true && (
                    <PrimaryButton
                        color="secondary"
                        onClick={saveAndAddAnother}
                        title="AddSpace.SaveAndAddAnother"
                        loading={isLoading && addAnother}
                    />
                )}
                <PrimaryButton
                    onClick={(): void => onClickToSave(true)}
                    title="Save"
                    id="CreateSpaceButton"
                    color="primary"
                    loading={isLoading && !addAnother}
                />
            </div>
            {error && <ResponseBox text={`ErrorCodes.${error.error}`} />}
        </div>
    );
};

export default SpaceAddForm;
