import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { useDispatch, useSelector } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { FLOOR_PLAN_SELECTED } from 'commons/src/analytics/AnalyticsEvents';
import { fullWidthListPlaceholder } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { getFloorPlanData } from '../../actions/floorPlanSpaceActions';
import { Store } from '../../reducers';
import AddFirstFloorPlan from './addFloorPlan/AddFirstFloorPlan';
import FloorPlanNavigation from './FloorPlanNavigation';
import FloorPlanView from './floorPlanViewer/FloorPlanView';
import styles from './SpaceFloorPlan.module.scss';

type Props = {
    locationId: string;
    fullscreenMode: boolean;
    toggleFullscreenMode: () => void;
    selectedFloorPlanId?: string;
    setSelectedFloorPlanId: (floorPlanId?: string) => void;
};

const SpaceFloorPlans = ({
    locationId,
    fullscreenMode,
    toggleFullscreenMode,
    setSelectedFloorPlanId,
    selectedFloorPlanId,
}: Props): ReactElement => {
    const dispatch = useDispatch();
    const { t: txt } = useTranslation();
    const [addingNewFloor, setAddingNewFloor] = useState(false);
    const { floorPlans, locationId: stateLocationId } = useSelector((state: Store) => state.floorPlansSpace);
    const floorPlanData = useSelector((state: Store) => state.floorPlansSpace.floorData);
    const { loading, error } = useSelector((state: Store) => state.requests.GET_SPACE_FLOOR_PLANS);
    const { loading: addFloorLoading, error: addFloorError } = useSelector(
        (state: Store) => state.requests.CREATE_SPACE_FLOOR_PLAN
    );

    const fetchFloorData = (floorId?: string): void => {
        if (floorId && !floorPlanData[floorId]) {
            dispatch(getFloorPlanData(locationId, floorId));
        }
    };

    useEffect(() => {
        if (addingNewFloor && !addFloorLoading && !addFloorError) {
            setAddingNewFloor(false);
            setSelectedFloorPlanId(floorPlans[floorPlans.length - 1].id);
        }
    }, [addFloorLoading]);

    const onSelectAddNewFloor = (): void => {
        setAddingNewFloor(true);
        setSelectedFloorPlanId(undefined);
    };

    const onSelectFloorPlan = (floorId?: string): void => {
        fetchFloorData(floorId);
        setSelectedFloorPlanId(floorId);
        setAddingNewFloor(false);
        analyticsLogger(FLOOR_PLAN_SELECTED, { floorPlanId: floorId });
    };

    if (!loading && floorPlans.length === 0) {
        return <AddFirstFloorPlan locationId={locationId} />;
    }

    return (
        <>
            {error && <ResponseBox text={txt(`ErrorCodes.${error.error}`)} />}
            <ReactPlaceholder
                ready={!loading || locationId === stateLocationId}
                customPlaceholder={fullWidthListPlaceholder}
            >
                <div className={styles.wrapper}>
                    <FloorPlanNavigation
                        selectedFloorPlanId={selectedFloorPlanId}
                        floorPlans={floorPlans}
                        setSelectedFloorPlanId={onSelectFloorPlan}
                        setAddingNewFloor={onSelectAddNewFloor}
                        addingNewFloor={addingNewFloor}
                        fullScreenMode={fullscreenMode}
                    />
                    <FloorPlanView
                        key={selectedFloorPlanId}
                        floorPlanId={selectedFloorPlanId}
                        addingNewFloor={addingNewFloor}
                        floorPlanData={floorPlanData[selectedFloorPlanId || '']}
                        locationId={locationId}
                        fullscreenMode={fullscreenMode}
                        toggleFullScreenMode={toggleFullscreenMode}
                    />
                </div>
            </ReactPlaceholder>
        </>
    );
};
export default SpaceFloorPlans;
