import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DeviceInfo from 'commons/src/components/device/DeviceInfo';
import LabeledText from 'commons/src/components/LabeledText';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { DeviceWithKeyInfo, SegmentProperties } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';

type ParentProps = {
    serialNumber: string;
    error: string;
};

type StateProps = {
    devices: { [serialNumber: string]: DeviceWithKeyInfo };
    segmentProperties: { [serialNumber: string]: SegmentProperties };
};

type Props = ParentProps & StateProps;

export const DeviceResultMissingDataComponent = (props: Props): React.ReactElement => {
    const { devices, error, serialNumber, segmentProperties } = props;
    const { t: txt } = useTranslation();
    const device = serialNumber && devices[serialNumber];
    const segmentProps: SegmentProperties = segmentProperties[serialNumber];

    if (!device) {
        return <div />;
    }
    return (
        <div className="form__wide-container insight-tile insight-tile--full-width insight-tile--single-line">
            <div className="insight-tile__header insight-tile__header--no-border">
                <h3 className="insight-tile__header__name">{device.segmentName}</h3>
                <DeviceInfo serialNumber={serialNumber} deviceType={device.type} />
                <div className="flex">
                    <div className="form__field--slim insight__slim-labeled-text">
                        <LabeledText
                            label="Floor"
                            id={`deviceFloor${serialNumber}`}
                            invalid={false}
                            value={segmentProps && segmentProps.floor !== undefined ? `${segmentProps.floor}` : ''}
                        />
                    </div>
                    <div className="form__field--slim insight__slim-labeled-text">
                        <LabeledText
                            label="RoomType"
                            id={`roomType${serialNumber}`}
                            invalid={false}
                            value={
                                segmentProps && segmentProps.roomType
                                    ? txt(`RoomTypeOptions.${segmentProps.roomType}`)
                                    : ''
                            }
                        />
                    </div>
                </div>
                <div className="device-insight__error-response">
                    <MaterialIcon extraClass="icon-color--red" name="error_outline" />
                    {txt(`ErrorCodes.${error}`)}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        devices: { devicesWithKeyInfo: devices },
        segmentPropertiesStore: { segmentProperties },
    } = state;
    return {
        devices,
        segmentProperties,
    };
};

export default connect(mapStateToProps)(DeviceResultMissingDataComponent);
