import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { deviceIsView, mapDeviceType } from 'commons/src/commonFunctions';
import CollapsableSectionHeader from 'commons/src/components/headers/CollapsableSectionHeader';
import { devicesWithBatterySettings } from 'commons/src/DeviceAndSensorLists';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { BuildingType, DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import BatterySettings from './BatterySettings';
import ConfigureGlowRing from './ConfigureGlowRing';
import LindabConnectionSection from './LindabConnectionSection';
import MoldRiskSection from './MoldRiskSection';
import PublicDeviceOverview from './PublicDeviceOverview';
import ViewDisplaySettings from './ViewDisplaySettings';

type StateProps = {
    configLoading: boolean;
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
};

export type ParentProps = {
    building: BuildingType;
};

export type Props = ParentProps & StateProps;

export const ConfigureDevicesPageComponent = ({
    building,
    devicesWithKeyInfo,
    configLoading,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const [glowRingConfigOpen, setGlowRingConfigOpen] = useState(false);
    const [publicDeviceSectionOpen, setPublicDeviceSectionOpen] = useState(false);
    const [batterySettingsOpen, setBatterySettingsOpen] = useState(false);
    const [displaySettingsOpen, setDisplaySettingsOpen] = useState(false);
    const [lindabSectionOpen, setLindabSectionOpen] = useState(false);
    const [moldRiskSectionOpen, setMoldRiskSectionOpen] = useState<boolean>(false);

    const buildingHasViewDevice =
        building &&
        building.devices.find(
            serialNumber =>
                devicesWithKeyInfo[serialNumber] &&
                deviceIsView(devicesWithKeyInfo[serialNumber].type as DeviceTypeNames)
        );

    const shouldHaveBatterySettings =
        building &&
        building.devices.find(
            serialNumber =>
                devicesWithKeyInfo[serialNumber] &&
                devicesWithBatterySettings.includes(devicesWithKeyInfo[serialNumber].type as DeviceTypeNames)
        );

    return (
        <>
            <h2 className="settings__header">{txt('Devices')}</h2>
            <div className="settings-details-container settings-details-container--margin">
                <CollapsableSectionHeader
                    openSection={setGlowRingConfigOpen}
                    headerIsOpen={glowRingConfigOpen}
                    headerText="BuildingSettings.GlowRingHeader"
                    subtext="BuildingSettings.GlowRingHeaderSubText"
                    loading={glowRingConfigOpen && configLoading}
                    testAttr="glow-ring-section"
                />
                {glowRingConfigOpen && !configLoading && <ConfigureGlowRing locationId={building.id} />}
            </div>
            {shouldHaveBatterySettings && (
                <div className="settings-details-container settings-details-container--margin">
                    <CollapsableSectionHeader
                        openSection={setBatterySettingsOpen}
                        headerIsOpen={batterySettingsOpen}
                        headerText="DeviceSettings.BatteryLife"
                        subtext="DeviceSettings.BatteryLifeDescription"
                        loading={batterySettingsOpen && configLoading}
                        testAttr="battery-life-section"
                    />
                    {batterySettingsOpen && !configLoading && (
                        <BatterySettings buildingDevices={building.devices} locationId={building.id} />
                    )}
                </div>
            )}
            {buildingHasViewDevice && (
                <div className="settings-details-container settings-details-container--margin">
                    <CollapsableSectionHeader
                        openSection={setDisplaySettingsOpen}
                        headerIsOpen={displaySettingsOpen}
                        headerText="DeviceSettings.Display"
                        subtext={txt('DeviceSettings.DisplaySubHeader', {
                            deviceType: txt(mapDeviceType(DeviceTypeNames.viewPlusBusiness)),
                        })}
                        loading={displaySettingsOpen && configLoading}
                        translateSubText={false}
                        testAttr="device-display-section"
                    />
                    {displaySettingsOpen && !configLoading && (
                        <ViewDisplaySettings
                            locationId={building.id}
                            goBack={(): void => setDisplaySettingsOpen(false)}
                            buildingDevices={building.devices}
                        />
                    )}
                </div>
            )}
            <div className="settings-details-container settings-details-container--margin">
                <CollapsableSectionHeader
                    openSection={setLindabSectionOpen}
                    headerIsOpen={lindabSectionOpen}
                    headerText="LindabConnection.LindabConnection"
                    subtext="LindabConnection.LindabConnectionSubHeader"
                    loading={lindabSectionOpen && configLoading}
                    testAttr="lindab-connection-section"
                />
                {lindabSectionOpen && !configLoading && <LindabConnectionSection locationId={building.id} />}
            </div>
            <div className="settings-details-container settings-details-container--margin">
                <CollapsableSectionHeader
                    openSection={setMoldRiskSectionOpen}
                    headerIsOpen={moldRiskSectionOpen}
                    headerText="MoldRiskIndicator.MoldRiskIndicator"
                    subtext="MoldRiskIndicator.EnableMoldRisk"
                    testAttr="mold-risk-section"
                />
                {moldRiskSectionOpen && (
                    <MoldRiskSection
                        locationId={building.id}
                        closeSection={(): void => setMoldRiskSectionOpen(false)}
                    />
                )}
            </div>
            <div className="settings-details-container settings-details-container--margin">
                <CollapsableSectionHeader
                    openSection={setPublicDeviceSectionOpen}
                    headerIsOpen={publicDeviceSectionOpen}
                    headerText="PublicDevices.PublicDevices"
                    subtext="PublicDevices.Description"
                    testAttr="public-devices-section"
                />
                {publicDeviceSectionOpen && <PublicDeviceOverview building={building} />}
            </div>
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        devices: { devicesWithKeyInfo },
        requests: {
            [RequestType.GetLocationConfiguration]: { loading: configLoading },
        },
    } = state;
    return {
        devicesWithKeyInfo,
        configLoading,
    };
};

export default connect(mapStateToProps)(ConfigureDevicesPageComponent);
