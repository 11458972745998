import * as Sentry from '@sentry/react';
import moment from 'moment';
import { LOGOUT_SUCCESS, LogOutSuccess } from '../actions/LoginAndRegisterActions';
import { SelectGroupActionType, SelectGroupReducerActions } from '../actions/selectGroupActions';
import {
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_ERROR,
    CHANGE_PASSWORD_SUCCESS,
    DomainLookupActionType,
    FETCH_PARTNER_PROPS_SUCCESS,
    FETCH_USER_INFO_SUCCESS,
    LookupDomainSuccess,
    SET_MOBILE_SETTINGS_MENU_IS_OPEN,
    SettingsActionType,
    SettingsReducerActions,
} from '../actions/SettingsActions';
import { getSelectedGroupFromStorage } from '../components/findUserType';
import config from '../config';
import { dateFormats, Environment, languages } from '../constants';
import { FeatureToggleEnum, GroupType } from '../models/commonEnums';
import {
    DashboardLocale,
    ErrorType,
    Group,
    PartnerLogoDetails,
    SelectedGroup,
    SubscriptionPlan,
    Units,
} from '../models/commonTypeScript';

export interface UserSettingsStore {
    userId?: string;
    loading: boolean;
    error?: ErrorType;
    language: DashboardLocale;
    email: string;
    userName: string;
    dateFormat: keyof typeof dateFormats;
    errorCode?: number;
    demoMode: boolean;
    groups: Group[];
    selectedGroup?: Group;
    intercomUserHash?: string;
    intercomUserHashB2B?: string;
    mobileMenuIsOpen: boolean;
    changePasswordError?: ErrorType;
    featureToggles: FeatureToggleEnum[];
    managedByOrganizationDetails?: PartnerLogoDetails;
    units: Units;
    sso: boolean;
    subscriptionForGroup?: SubscriptionPlan;
}

const userLanguage = window.navigator.language || window.navigator.userLanguage || '';
const initialState: UserSettingsStore = {
    loading: true,
    error: undefined,
    language: 'en',
    units: { radonUnit: 'bq', tempUnit: 'c', pressureUnit: 'hpa', lengthUnit: 'm', vocUnit: 'ppb' },
    dateFormat: userLanguage.toLowerCase() === 'en-us' ? dateFormats.US.type : dateFormats.EUR.type,
    email: '',
    userName: '',
    errorCode: undefined,
    demoMode: false,
    groups: [],
    selectedGroup: undefined,
    intercomUserHash: undefined,
    intercomUserHashB2B: undefined,
    userId: undefined,
    mobileMenuIsOpen: false,
    changePasswordError: undefined,
    featureToggles: [],
    managedByOrganizationDetails: undefined,
    sso: false,
};

export const setSelectedAccount = (groups: Group[]): Group | undefined => {
    const previouslySelectedGroupInStorage: SelectedGroup | null = getSelectedGroupFromStorage();

    const previouslySelectedGroup: Group | undefined = groups.find(group => {
        if (
            previouslySelectedGroupInStorage !== null &&
            previouslySelectedGroupInStorage.groupType === GroupType.consumer &&
            group.groupType === GroupType.consumer
        )
            return group;
        return group.id === previouslySelectedGroupInStorage?.userGroupId;
    });

    if (previouslySelectedGroup) {
        return previouslySelectedGroup;
    }

    const firstOrgAccountThenConsumer: Group | undefined =
        groups.find(it => it.groupType !== GroupType.consumer) ||
        groups.find(group => group.groupType === GroupType.consumer);

    if (firstOrgAccountThenConsumer) {
        return firstOrgAccountThenConsumer;
    }
    return undefined;
};

const getValidLanguageTagForMoment = (languageTag: string): string => {
    const validLanguageTag = Object.keys(languages).includes(languageTag);
    if (validLanguageTag) return languageTag;
    const initialPartOfLanguageString = (languageTag?.length > 2 && languageTag.split('-')[0]) || '';
    if (Object.keys(languages).includes(initialPartOfLanguageString.toLowerCase())) {
        return initialPartOfLanguageString.toLowerCase();
    }
    if (['no', 'nn'].includes(languageTag)) {
        return 'nb';
    }
    return 'en';
};
const setLanguage = (language: DashboardLocale): DashboardLocale => {
    const validLanguageTag = getValidLanguageTagForMoment(language);
    moment.locale(validLanguageTag);
    return validLanguageTag as DashboardLocale;
};

const getFeatureToggles = (groups: Group[] = [], selectedUserGroupId: string): FeatureToggleEnum[] => {
    const selectedGroup = groups.find(group => group.id === selectedUserGroupId);
    return selectedGroup && selectedGroup.features ? selectedGroup.features : [];
};

export default (
    state = initialState,
    action: SettingsReducerActions | LogOutSuccess | LookupDomainSuccess | SelectGroupReducerActions
): UserSettingsStore => {
    switch (action.type) {
        case FETCH_USER_INFO_SUCCESS: {
            const selectedGroup = setSelectedAccount(action.groups);
            if (config.env === Environment.prod || config.env === Environment.dev) {
                Sentry.setUser({ id: action.userId });
            }
            return {
                ...state,
                email: action.email,
                userName: action.name,
                dateFormat: action.dateFormat,
                units: action.units,
                error: false,
                errorCode: undefined,
                loading: false,
                language: setLanguage(action.language),
                demoMode: state.demoMode, // TODO Remove from state, load from localStorage
                groups: action.groups,
                selectedGroup,
                intercomUserHash: action.intercomUserHash,
                intercomUserHashB2B: action.intercomUserHashB2B,
                userId: action.userId,
                featureToggles: (selectedGroup && selectedGroup.features) || [],
            };
        }
        case SettingsActionType.UpdateUserProfileSuccess:
            return {
                ...state,
                userName: action.name,
            };
        case SettingsActionType.UpdateUserPreferencesSuccess:
            return {
                ...state,
                units: action.request.units,
                dateFormat: action.request.dateFormat,
                ...(action.request.language && { language: setLanguage(action.request.language) }),
            };
        case CHANGE_PASSWORD:
            return {
                ...state,
                loading: true,
                changePasswordError: undefined,
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                changePasswordError: undefined,
            };
        case CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                changePasswordError: action.error,
            };
        case SelectGroupActionType.SelectGroupInit:
            return {
                ...state,
                loading: true,
                featureToggles: getFeatureToggles(state.groups, action.userGroupId),
                selectedGroup: state.groups.find(it => it.id === action.userGroupId),
                subscriptionForGroup: undefined,
            };
        case SelectGroupActionType.SelectGroupSuccess:
            return {
                ...state,
                loading: false,
            };
        case SelectGroupActionType.GetSubscriptionForGroupSuccess:
            return {
                ...state,
                subscriptionForGroup: action.subscription,
            };
        case FETCH_PARTNER_PROPS_SUCCESS:
            return {
                ...state,
                managedByOrganizationDetails: action.partnerProps,
            };
        case LOGOUT_SUCCESS:
            return initialState;
        case SET_MOBILE_SETTINGS_MENU_IS_OPEN:
            return {
                ...state,
                mobileMenuIsOpen: action.isOpen,
            };
        case DomainLookupActionType.LookupDomainSuccess:
            return {
                ...state,
                sso: action.response.isActiveSsoDomain,
            };
        default:
            return state;
    }
};
