import React, { useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { generatePath, Outlet, useParams } from 'react-router-dom';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { BUILDING_VIEWED_BUILDING } from 'commons/src/analytics/AnalyticsEvents';
import { isVirtualDevice } from 'commons/src/commonFunctions';
import Error from 'commons/src/components/errorComponents/Error';
import PageHeader from 'commons/src/components/headers/PageHeader';
import TabNumberBadge from 'commons/src/components/headers/TabNumberBadge';
import { businessPaths as paths, roleRestrictions } from 'commons/src/constants';
import { AnyDeviceType, BuildingType, DeviceWithKeyInfo, ErrorType } from 'commons/src/models/commonTypeScript';
import { TabOption } from 'commons/src/models/menuModels';
import { fetchBuildingHealthSummary } from '../../../actions/buildingHealthActions';
import { fetchBuildingWithDevices } from '../../../actions/locationActions';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';

type StateProps = {
    loading: boolean;
    error?: ErrorType;
    buildings: Record<string, BuildingType>;
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
};
export type Props = StateProps;

export const BuildingPageLayoutComponent = ({
    loading,
    error,
    buildings,
    devicesWithKeyInfo,
}: Props): React.ReactElement | null => {
    const { buildingId: locationId } = useParams<'buildingId'>() as { buildingId: string };
    const building = buildings[locationId];

    const prevLoadingRef = useRef(loading);
    const locationError = prevLoadingRef.current && !loading && !building;
    const dispatch = useDispatch();

    const offlineDevicesTotal = useSelector((state: Store) => {
        const buildingHealth = state.buildingsHealth[locationId];
        if (!buildingHealth) return 0;
        return buildingHealth.hubStats.offline + buildingHealth.deviceStats.offline;
    });

    const healthRequest = useSelector((state: Store) => state.requests.FETCH_BUILDING_HEALTH_SUMMARY);

    useEffect(() => {
        if (!healthRequest.loading && !offlineDevicesTotal) {
            dispatch(fetchBuildingHealthSummary(locationId));
        }
    }, [locationId]);

    useEffect(() => {
        dispatch(fetchBuildingWithDevices(locationId));
    }, []);

    useEffect((): void => {
        if (building) {
            analyticsLogger(BUILDING_VIEWED_BUILDING, { pageType: PageType.Building, buildingName: building.name });
        }
    }, [building]);

    const tabOptions: TabOption[] = [
        {
            text: 'Spaces.Spaces',
            id: 'buildingSpaces',
            path: generatePath(`/${paths.buildingPage}/spaces`, { buildingId: locationId }),
            requiredRoleLevel: roleRestrictions.building,
            testAttr: 'spaces',
            route: `${paths.buildingPage}/spaces`,
            requiredGroupTypes: [],
        },
        {
            text: 'Insight.Insights',
            id: 'buildingOverview',
            path: generatePath(`/${paths.buildingPage}/insights`, { buildingId: locationId }),
            requiredRoleLevel: roleRestrictions.building,
            testAttr: 'overview',
            route: `${paths.buildingPage}/insights`,
            requiredGroupTypes: [],
            subNavigation: [
                {
                    id: 'airQuality',
                    queryParam: 'air',
                    title: 'BuildingInsight.AirQuality',
                    testAttr: 'air-quality-navigation',
                },
                {
                    id: 'energy',
                    queryParam: 'energy',
                    title: 'BuildingEnergy.Energy',
                    testAttr: 'energy-navigation',
                },
                {
                    id: 'presence',
                    queryParam: 'presence',
                    title: 'PresenceInsight.SpaceUsage',
                    testAttr: 'presence-navigation',
                },
            ],
        },
        {
            text: 'BuildingStatus.Status',
            id: 'buildingStatus',
            path: generatePath('/buildings/:buildingId/status', { buildingId: locationId }),
            requiredRoleLevel: roleRestrictions.building,
            testAttr: 'status',
            route: 'buildings/:buildingId/status',
            badge: offlineDevicesTotal ? <TabNumberBadge value={offlineDevicesTotal} /> : <div />,
            requiredGroupTypes: [],
        },
        {
            text: 'Settings',
            id: 'buildingSettings',
            path: generatePath(`/${paths.buildingPage}/settings`, { buildingId: locationId }),
            requiredRoleLevel: roleRestrictions.editDeviceOrBuilding,
            testAttr: 'settings',
            route: `${paths.buildingPage}/settings`,
            requiredGroupTypes: [],
        },
    ];

    const buildingWithVirtualDevices =
        building &&
        building.devices.find(snr => {
            const deviceType = devicesWithKeyInfo[snr] && devicesWithKeyInfo[snr].type;
            return deviceType && isVirtualDevice(deviceType as AnyDeviceType);
        });

    const virtualDevicesTab = {
        text: 'Building.Control',
        id: 'buildingControl',
        path: generatePath(`/${paths.buildingPage}/control`, { buildingId: locationId }),
        requiredRoleLevel: roleRestrictions.editDeviceOrBuilding,
        testAttr: 'control-devices',
        route: `${paths.buildingPage}/control`,
        requiredGroupTypes: [],
    };
    if (buildingWithVirtualDevices) {
        tabOptions.splice(2, 0, virtualDevicesTab);
    }

    if (error || locationError) {
        return <Error />;
    }

    return (
        <div data-building-page>
            <PageHeader
                title={building ? building.name : ''}
                subHeader={(building && building.address) || ''}
                tabs={tabOptions}
            />
            <Outlet />
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        buildings: { buildings },
        devices: { devicesWithKeyInfo },
        requests: {
            [RequestType.FetchBuildingWithDevices]: {
                loading: fetchBuildingLoading,
                error: fetchBuildingWithDevicesError,
            },
            [RequestType.FetchBuilding]: { error: fetchBuildingError },
        },
    } = state;

    return {
        loading: fetchBuildingLoading,
        error: fetchBuildingWithDevicesError || fetchBuildingError,
        buildings,
        devicesWithKeyInfo,
    };
};

export default connect(mapStateToProps)(BuildingPageLayoutComponent);
