import React, { ReactElement, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { FLOOR_PLAN_ADDED } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import Input from 'commons/src/components/input/Input';
import NumberInput from 'commons/src/components/input/Number';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { createSpaceFloorPlan } from '../../../actions/floorPlanSpaceActions';
import PdfImage from '../../../img/pdf_icon.png';
import { Store } from '../../../reducers';
import styles from './AddFloorPlan.module.scss';

type Props = {
    locationId: string;
};
const AddFloorPlan = ({ locationId }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const imageMaxSize = 2000000;
    const [floorName, setFloorName] = useState('');
    const [floorNr, setFloorNr] = useState<number>(parseInt('', 10));
    const [image, setImage] = useState('');
    const [displayValidation, setDisplayValidation] = useState(false);
    const [imageSizeTooLarge, setImageSizeTooLarge] = useState(false);
    const [invalidFileType, setInvalidFileType] = useState(false);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState('');
    const { loading, error } = useSelector((state: Store) => state.requests.CREATE_SPACE_FLOOR_PLAN);

    const validImage = image.length > 0 && !imageSizeTooLarge && !invalidFileType;

    const handleNameChange = ({ currentTarget }: SyntheticEvent<HTMLInputElement>): void => {
        setFloorName(currentTarget.value.trim());
    };

    const handleImageChange = ({ currentTarget }: SyntheticEvent<HTMLInputElement>): void => {
        const file = currentTarget?.files && currentTarget.files.length > 0 && currentTarget.files[0];
        setInvalidFileType(false);
        setImageSizeTooLarge(false);
        if (!file) return;
        if (!file.type.includes('image') && !file.type.includes('pdf')) {
            setInvalidFileType(true);
        } else if (file.size > imageMaxSize) {
            setImageSizeTooLarge(true);
        }

        setImageFile(file);
        if (file.type.includes('pdf')) {
            setImage(PdfImage);
            setFileName(file.name);
            return;
        }

        // eslint-disable-next-line no-undef
        const reader = new FileReader();
        reader.onloadend = (): void => {
            const base64Image: string = reader.result as string;
            setImage(base64Image);
            setFileName(file.name);
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = (): void => {
        const floorIsNumber = !Number.isNaN(floorNr);
        const valid = floorName.length > 0 && floorIsNumber && validImage && imageFile != null;
        if (!valid) {
            setDisplayValidation(true);
        } else {
            analyticsLogger(FLOOR_PLAN_ADDED, {});
            dispatch(createSpaceFloorPlan({ name: floorName, floor: Number(floorNr) }, imageFile, locationId));
        }
    };

    const invalidFileMessage = (): string => {
        if (invalidFileType) {
            return txt('SpaceFloorPlan.InvalidFloorPlanFileType');
        }
        if (imageSizeTooLarge) {
            return txt('ImageTooLargeMaxSize', { size: `${imageMaxSize / 1000000}MB` });
        }
        return '';
    };

    const clearFloorFile = (): void => {
        setFileName('');
        setImage('');
        setImageFile(null);
        setImageSizeTooLarge(false);
    };

    const triggerFileUpload = (e: SyntheticEvent<HTMLElement>): void => {
        e.stopPropagation();
        const fileLoader = document.getElementById('floorFileUploader');
        if (fileLoader) {
            fileLoader.click();
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {image ? (
                <div className={styles.selectedImageWrapper}>
                    <img src={image} className={styles.selectedImage} alt={txt('SpaceFloorPlan.BuildingImage')} />
                    <div>
                        <div className={styles.fileName}>{fileName}</div>
                        <div className={styles.invalidFileMessage}>{invalidFileMessage()}</div>
                        <TertiaryButton onClick={clearFloorFile} title="Change" />
                    </div>
                </div>
            ) : (
                <div
                    className={styles.uploadImageSection}
                    role="button"
                    tabIndex={0}
                    onClick={triggerFileUpload}
                    onKeyUp={(e): void => {
                        if (e.key === 'Enter') triggerFileUpload(e);
                    }}
                >
                    <div className={styles.selectImage}>
                        <MaterialIcon extraClass={styles.placeholderImage} name="photo" />
                        <PrimaryButton
                            color="secondary"
                            onClick={triggerFileUpload}
                            title="SpaceFloorPlan.UploadImage"
                        />
                        <div>{txt('SpaceFloorPlan.AcceptedFloorPlanFormat')}</div>
                    </div>
                </div>
            )}
            <input
                type="file"
                style={{ display: 'none' }}
                id="floorFileUploader"
                onChange={handleImageChange}
                accept="image/jpeg,image/png,application/pdf"
            />
            <div className={styles.floorDetails}>
                <NumberInput
                    id="floorNr"
                    validate={displayValidation && Number.isNaN(floorNr)}
                    onChange={(e): void => setFloorNr(parseInt(e.currentTarget.value, 10))}
                    step={1}
                    label={txt('Floor')}
                    width="minimum"
                />
                <Input
                    type="text"
                    id="floorname"
                    label="Name"
                    isValid={floorName.length > 0}
                    onChange={handleNameChange}
                    validate={displayValidation && floorName.length < 1}
                    maxLength={50}
                    noBottomMargin
                />
                <PrimaryButton
                    color="primary"
                    type="button"
                    title="Add"
                    loading={loading}
                    onClick={handleSubmit}
                    testId="submit-floorplan"
                    disabled={!validImage}
                />
            </div>
            {error && <ResponseBox text={`ErrorCodes.${error.error}`} />}
        </form>
    );
};
export default AddFloorPlan;
