import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import PageHeader from 'commons/src/components/headers/PageHeader';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { dateFormats } from 'commons/src/constants';
import { subscriptionDevices } from 'commons/src/DeviceAndSensorLists';
import { GroupType } from 'commons/src/models/commonEnums';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { getCostPreviewForUpdateSubscription, getProductRatePlans } from '../../../../api/subscriptionApi';
import { paths } from '../../../../constants';
import { BillingType } from '../../../../models/commonEnums';
import {
    EstimatedSubscription,
    RatePlansPerPeriod,
    Subscription,
    SubscriptionSeats,
    SubscriptionsOrderRequest,
} from '../../../../models/subscriptionModels';
import {
    getRatePlansForSelection,
    getSeatsPerDevice,
    getServicePeriodName,
} from '../StartSubscription/subscriptionFunctions';
import { getFormattedCost } from '../SubscriptionCommons';
import AddSeatsFormView from './AddSeatsFormView';
import AddSeatsPreview from './AddSeatsPreview';
import AddSeatsSuccessView from './AddSeatsSuccessView';

type ParentProps = {
    error?: ErrorType;
    loading: boolean;
    customerSubscription?: Subscription[];
    dateFormat: keyof typeof dateFormats;
    selectedGroupType?: GroupType;
    userAllowedToManageSubscription: boolean;
    childUserGroupId?: string;
    deviceCounts?: { [device: string]: number };
};

type Props = ParentProps;

const AddSeatsPage = ({
    error,
    loading,
    customerSubscription,
    dateFormat,
    selectedGroupType,
    userAllowedToManageSubscription,
    childUserGroupId,
    deviceCounts,
}: Props): React.ReactElement => {
    const { subscriptionNumber } = useParams();
    const { t: txt } = useTranslation();
    const navigate = useNavigate();

    const subscriptionToUpdate = customerSubscription?.find(
        subscription => subscription.subscriptionNumber === subscriptionNumber
    );
    const today = dayjs().format('YYYY-MM-DD');

    if (!(userAllowedToManageSubscription || loading)) {
        navigate(-1);
    }

    useEffect(() => {
        document.body.className = 'blue-body';
        return (): void => {
            document.body.className = '';
        };
    }, []);

    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [stepNumber, setStepNumber] = useState(1);
    const [poNumber, setPoNumber] = useState(subscriptionToUpdate?.poNumber || '');
    const [seatsPerDevice, setSeatsPerDevice] = useState<SubscriptionSeats[]>([]);
    const [ratePlans, setRatePlans] = useState<RatePlansPerPeriod | undefined>(undefined);
    const [ratePlansLoading, setRatePlansLoading] = useState(false);
    const [ratePlansError, setRatePlansError] = useState<ErrorType | undefined>(undefined);
    const [costPreview, setCostPreview] = useState<EstimatedSubscription | undefined>(undefined);
    const [costPreviewError, setCostPreviewError] = useState<ErrorType | undefined>(undefined);
    const [costPreviewLoading, setCostPreviewLoading] = useState(false);
    const [duplicateDeviceTypeEntries, setDuplicateDeviceTypeEntries] = useState(false);

    const servicePeriodName = subscriptionToUpdate && getServicePeriodName(subscriptionToUpdate?.durationInYears);
    const ratePlansForSubscription = ratePlans && servicePeriodName && ratePlans[servicePeriodName].products;

    const onClose = (): void => {
        if (selectedGroupType === GroupType.partner && childUserGroupId) {
            navigate({
                pathname: `/${generatePath(paths.partnerCustomerPage, {
                    childUserGroupId,
                })}`,
            });
        } else {
            navigate({
                pathname: `/${generatePath(paths.subscription)}`,
            });
        }
    };

    const getRatePlans = useCallback(async () => {
        setRatePlansLoading(true);
        const response = await getProductRatePlans().catch(err => {
            setRatePlansError(err);
            setRatePlansLoading(false);
        });
        if (response) {
            setRatePlans(response.plans);
            setRatePlansLoading(false);
        }
    }, []);

    useEffect(() => {
        if (subscriptionToUpdate) {
            const subscriptionDeviceTypes = subscriptionToUpdate.deviceSeats;
            const subscriptionIncludesTwoElementsWithSameDeviceType = subscriptionDeviceTypes.some((sub, index1) =>
                subscriptionDeviceTypes.find((sub2, index2) => index1 !== index2 && sub2.deviceType === sub.deviceType)
            );

            if (subscriptionIncludesTwoElementsWithSameDeviceType) setDuplicateDeviceTypeEntries(true);
        }
    }, [subscriptionToUpdate]);

    useEffect(() => {
        if (subscriptionToUpdate && ratePlansForSubscription) {
            const seatOptions = getSeatsPerDevice({}, ratePlansForSubscription).map(deviceSets => {
                const deviceInSubscription = subscriptionToUpdate.deviceSeats.find(
                    seat => seat.deviceType === deviceSets.deviceType
                );
                const deviceSeatsAlreadyInSubscription = deviceInSubscription?.seats || 0;
                const ratePlanForDevice =
                    ratePlansForSubscription &&
                    ratePlansForSubscription[deviceSets.deviceType as keyof typeof ratePlansForSubscription];
                const priceInfoForDevice =
                    ratePlanForDevice &&
                    ratePlanForDevice.find(ratePlan => ratePlan.billingType === BillingType.FULL)?.productPrice;
                return {
                    ...deviceSets,
                    deployedSeats: deviceSeatsAlreadyInSubscription,
                    seats: deviceSeatsAlreadyInSubscription,
                    ratePlanId: deviceInSubscription?.ratePlanId,
                    chargeNumber: deviceInSubscription?.chargeNumber,
                    costPerDevice:
                        priceInfoForDevice &&
                        getFormattedCost(priceInfoForDevice.currency, priceInfoForDevice.unitAmount),
                };
            });
            setSeatsPerDevice(seatOptions);
        }
    }, [subscriptionToUpdate, ratePlansForSubscription]);

    useEffect(() => {
        if (subscriptionToUpdate) {
            setPoNumber(subscriptionToUpdate.poNumber);
        }
    }, [subscriptionToUpdate]);

    useEffect(() => {
        if (userAllowedToManageSubscription) {
            getRatePlans().catch(err => {
                setRatePlansError(err);
                setRatePlansLoading(false);
            });
        }
    }, [userAllowedToManageSubscription]);

    const getCost = useCallback(async (groupType: GroupType, payload?: SubscriptionsOrderRequest) => {
        setCostPreviewError(undefined);
        setCostPreviewLoading(true);
        if (payload) {
            const response = await getCostPreviewForUpdateSubscription(
                groupType,
                childUserGroupId as string,
                payload,
                subscriptionNumber as string
            ).catch((err: ErrorType) => {
                setCostPreviewError(err);
            });
            if (response) {
                setCostPreview(response);
            }
        }
        setCostPreviewLoading(false);
    }, []);

    const getSubscriptionPayload = (updatedSeats?: SubscriptionSeats[]): SubscriptionsOrderRequest | undefined => {
        if (subscriptionToUpdate && servicePeriodName) {
            const legacySubscriptions: SubscriptionSeats[] = subscriptionToUpdate.deviceSeats
                .filter(sub => !subscriptionDevices.includes(sub.deviceType))
                .map(sub => ({
                    deviceType: sub.deviceType,
                    seats: sub.seats,
                    ratePlanId: sub.ratePlanId,
                    chargeNumber: sub.chargeNumber,
                    deployedSeats: 0, // this is irrelevant and set to 0
                    productRatePlanChargeId: sub.productRatePlanChargeId, // this needs to be included here as the rate plan for these subscriptions are not included in the rate plan list we get
                    productRatePlanId: sub.productRatePlanId,
                }));
            const seatsForSubscription = updatedSeats || seatsPerDevice;
            const productRatePlans = ratePlans
                ? getRatePlansForSelection(ratePlans, servicePeriodName, [
                      ...seatsForSubscription,
                      ...legacySubscriptions,
                  ])
                : [];

            return {
                startDate: today,
                purchaseOrderNumber: poNumber || '',
                termPeriod: subscriptionToUpdate.durationInYears * 12,
                productRatePlans,
            };
        }
        return undefined;
    };

    const fetchLatestCostPreview = (groupType: GroupType, updatedSeats: SubscriptionSeats[]): void => {
        const payload = getSubscriptionPayload(updatedSeats);
        if (payload) {
            getCost(groupType, payload).catch(err => {
                setCostPreviewError(err);
            });
        }
    };

    const updateSeats = (newSeats: SubscriptionSeats[]): void => {
        setSeatsPerDevice(newSeats);
        if (timer) {
            clearTimeout(timer);
        }
        const timerRef = setTimeout(() => {
            if (selectedGroupType != null) {
                fetchLatestCostPreview(selectedGroupType, newSeats);
            }
        }, 1000);
        setTimer(timerRef);
    };

    if (duplicateDeviceTypeEntries) {
        return (
            <>
                <PageHeader title={txt('Subscription.AddSeatsHeader')} />
                <div className="page-wrapper__medium">
                    <ResponseBox text="ErrorCodes.DuplicateSubscriptionDeviceType" />
                </div>
            </>
        );
    }

    return (
        <>
            <PageHeader title={txt('Subscription.AddSeatsHeader')} />
            <ReactPlaceholder ready={!loading && !ratePlansLoading} customPlaceholder={mediumFormLoader}>
                {error && (
                    <div className="page-wrapper__medium">
                        <ResponseBox text={`ErrorCodes.${error.error}`} />
                    </div>
                )}
                {subscriptionToUpdate && stepNumber === 1 && (
                    <AddSeatsFormView
                        subscription={subscriptionToUpdate}
                        poNumber={poNumber}
                        updatePONumber={setPoNumber}
                        setSeatsPerDevice={updateSeats}
                        seatsPerDevice={seatsPerDevice}
                        setStepNumber={setStepNumber}
                        costPreview={costPreview}
                        costPreviewLoading={costPreviewLoading}
                        dateFormat={dateFormat}
                        customerSubscriptions={customerSubscription}
                        costPreviewError={costPreviewError}
                        ratePlanError={ratePlansError}
                        deviceCounts={deviceCounts}
                    />
                )}
                {subscriptionToUpdate && stepNumber === 2 && (
                    <AddSeatsPreview
                        costPreview={costPreview}
                        oldSubscription={subscriptionToUpdate}
                        childUserGroupId={childUserGroupId}
                        dateFormat={dateFormat}
                        poNumber={poNumber}
                        setStepNumber={setStepNumber}
                        subscriptionPayload={getSubscriptionPayload()}
                        groupType={selectedGroupType}
                        userAllowedToManageSubscription={userAllowedToManageSubscription}
                    />
                )}
                {subscriptionToUpdate && stepNumber === 3 && (
                    <AddSeatsSuccessView
                        poNumber={poNumber}
                        dateFormat={dateFormat}
                        oldSubscription={subscriptionToUpdate}
                        costPreview={costPreview}
                        onClose={onClose}
                    />
                )}
                {!loading && !subscriptionToUpdate && (
                    <div className="page-wrapper__medium">
                        <ResponseBox text="AddSeats.SubscriptionNotFound" />
                    </div>
                )}
            </ReactPlaceholder>
        </>
    );
};

export default AddSeatsPage;
