import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import DotNavigation from 'commons/src/components/navigation/DotNavigation';
import { paths } from 'commons/src/constants';
import { EntityType } from 'commons/src/models/commonEnums';
import { IndoorSpace, HubsAndDevices } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import { spacesSelector } from '../../spaces/space/spaceSelectors';
import styles from './FloorMapSensorValues.module.scss';
import { DeviceDetails, HubDetails } from './HubsAndDevicesDetails';

type Props = {
    onClose: () => void;
    selectedSpaceId: string;
    locationId: string;
};

const FloorMapSensorValuesComponent = ({ selectedSpaceId, locationId, onClose }: Props): ReactElement => {
    const [activeDeviceIndex, setActiveDevice] = useState<number>(0);
    const navigate = useNavigate();
    const { t: txt } = useTranslation();

    const {
        spaces: { spaces },
    } = useSelector((store: Store) => spacesSelector(store, locationId));
    const goToSpace = (): void => navigate(`/${paths.buildings}/${locationId}/spaces/${selectedSpaceId}`);

    const selectedSpace = spaces.find(it => it.id === selectedSpaceId) as IndoorSpace | undefined;
    if (!selectedSpace) return <div />;

    const hubsAndDevices: HubsAndDevices[] = [
        ...selectedSpace.devices.map(device => ({
            ...device,
            entityType: EntityType.DEVICE as const,
        })),
        ...selectedSpace.hubs.map(hub => ({
            ...hub,
            entityType: EntityType.HUB as const,
        })),
    ];

    const renderHeader = (): string =>
        hubsAndDevices.length === 0 ? selectedSpace.name : hubsAndDevices[activeDeviceIndex]?.segmentName;

    const renderItem = (): ReactElement => {
        const item = hubsAndDevices?.[activeDeviceIndex];
        if (!item) {
            return (
                <div className={styles.devices}>
                    <div className={styles.noDeviceInSpace}>{txt('Spaces.NoDevices')}</div>
                </div>
            );
        }

        if (item.entityType === EntityType.DEVICE) {
            return (
                <div className={styles.devices} key={item.serialNumber}>
                    <DeviceDetails item={item} />
                </div>
            );
        }

        if (item.entityType === EntityType.HUB) {
            return (
                <div className={styles.devices} key={item.serialNumber}>
                    <HubDetails item={item} />
                </div>
            );
        }

        return (
            <div className={styles.devices}>
                <div className={styles.noDeviceInSpace}>{txt('Spaces.NoDevices')}</div>
            </div>
        );
    };

    return (
        <div className={styles.body}>
            <div className={styles.header}>
                <div className={styles.headerTitle}>{renderHeader()}</div>
                <TertiaryButton onClick={onClose} noColor icon={<MaterialIcon name="close" />} />
            </div>
            <div className={styles.content}>
                {renderItem()}
                <DotNavigation
                    items={hubsAndDevices}
                    activeIndex={activeDeviceIndex}
                    setActiveIndex={setActiveDevice}
                    getKey={(item): string => item?.serialNumber}
                />
                <div className={styles.toSpaceButton}>
                    <TertiaryButton
                        onClick={goToSpace}
                        title="Spaces.GoToSpace"
                        size="medium"
                        trailingIcon={<MaterialIcon name="arrow_forward" />}
                    />
                </div>
            </div>
        </div>
    );
};

export default FloorMapSensorValuesComponent;
