import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import DropdownList from 'commons/src/components/dropdown/DropdownList';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { PlacementType } from 'commons/src/models/commonEnums';
import { FloorPlanData, SpaceFloorPlanMode } from '../../../models/spaceFloorPlanModels';
import { CreateSpacePayload, IndoorSpace } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import SpaceAddForm from '../../spaces/addEdit/SpaceAddForm';
import { spacesSelector } from '../../spaces/space/spaceSelectors';
import styles from './FloorMapSpaceSelector.module.scss';

type Props = {
    floorData: FloorPlanData;
    locationId: string;
    setSelectedSpace: (spaceId: string) => void;
    setSpacePayload: (payload: CreateSpacePayload) => void;
    setMode: (mode: SpaceFloorPlanMode | undefined) => void;
    floorNumber?: number;
};

const FloorMapSpaceSelector = ({
    floorData,
    locationId,
    setSelectedSpace,
    setSpacePayload,
    setMode,
    floorNumber,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const {
        spaces: { spaces },
    } = useSelector((store: Store) => spacesSelector(store, locationId));
    const floorPlanIds = useSelector((state: Store) => state.floorPlansSpace.floorPlans.map(floorPlan => floorPlan.id));
    const indoorSpaces = useMemo(() => {
        return spaces.filter(space => space.placement === PlacementType.ROOM) as IndoorSpace[];
    }, [spaces]);

    const [showDropdown, setShowDropdown] = useState<boolean>(true);
    const [showSpaceHeader, setShowSpaceHeader] = useState<boolean>(true);

    const selectSpace = (spaceId: string): void => {
        setSelectedSpace(spaceId);
        setMode(SpaceFloorPlanMode.EDIT_NEW);
    };

    const spaceList = (): ReactElement => {
        const spacesAlreadyMapped = floorData.spaces.map(space => space.id);
        const options = indoorSpaces
            .filter(space => {
                const isMapped = spacesAlreadyMapped.includes(space.id);
                const isPlanInUse = space.floorPlanId ? floorPlanIds.includes(space.floorPlanId) : false;
                return !isMapped && !isPlanInUse;
            })
            .map(space => ({
                id: space.id,
                text: space.name,
            }));

        return (
            <div className={styles.spaceList}>
                <DropdownList
                    onSelect={selectSpace}
                    options={options}
                    title="SpaceFloorPlan.SelectOrCreateSpace"
                    id="space-selector"
                    onButtonClick={(): void => setShowDropdown(false)}
                    buttonText="SpaceFloorPlan.CreateSpace"
                />
            </div>
        );
    };

    const onSaveSpace = (payload: CreateSpacePayload): void => {
        setShowDropdown(true);
        setSpacePayload(payload);
        setMode(SpaceFloorPlanMode.EDIT_NEW);
    };

    return showDropdown ? (
        spaceList()
    ) : (
        <div className={styles.spaceForm}>
            {showSpaceHeader && (
                <div className={styles.spaceFormHeader}>
                    <h1 className="modal__content__title">{txt('AddSpace.AddSpace')}</h1>
                    <TertiaryButton
                        onClick={(): void => setMode(undefined)}
                        noColor
                        icon={<MaterialIcon name="close" />}
                        testAttr="close"
                    />
                </div>
            )}
            <SpaceAddForm
                onClose={(): void => setMode(undefined)}
                locationId={locationId}
                floorNumber={floorNumber}
                addSingleSpace
                onSave={onSaveSpace}
                isLoading={false}
                setEnableHeader={setShowSpaceHeader}
            />
        </div>
    );
};

export default FloorMapSpaceSelector;
