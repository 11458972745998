import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { sensorUnits } from '../../constants';
import { thresholdsModalSensors } from '../../DeviceAndSensorLists';
import { SensorThresholds } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import PrimaryButton from '../buttons/PrimaryButton';
import { SensorIcon } from '../sensors/SensorUnit';
import ModalHeader from './ModalHeader';
import ThresholdLimits from './ThresholdLimits';

type ParentProps = {
    onClose: () => void;
};
type StateProps = {
    thresholds: { [sensor: string]: SensorThresholds };
};

type Props = ParentProps & StateProps;

const ThresholdsModal = ({ onClose, thresholds }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const sensorThresholds = (): React.ReactElement[] =>
        thresholdsModalSensors.map(sensor => {
            const thresholdDetails = thresholds[sensor];
            const unit = thresholdDetails && sensorUnits[thresholdDetails.unit as keyof typeof sensorUnits];
            return (
                <div key={sensor} className="modal__thresholds__sensor-wrapper">
                    <div className="modal__thresholds__sensor-wrapper__header">
                        <SensorIcon sensor={sensor} />
                        <div className="text-large text-uppercase">{`${txt(sensor)}`}</div>
                    </div>
                    <ThresholdLimits ranges={thresholdDetails?.ranges || []} sensorType={sensor} unit={unit} />
                </div>
            );
        });

    return (
        <Modal
            isOpen
            appElement={document.body}
            onRequestClose={onClose}
            className="modal__content modal__content--size-large"
            overlayClassName="modal modal__overlay"
        >
            <ModalHeader headerText="RecommendedSensorThresholds" onClose={onClose} />
            <div className="modal__content__body modal__content__body--flex">{sensorThresholds()}</div>
            <div className="modal__content__buttons">
                <PrimaryButton title="Close" color="primary" onClick={onClose} />
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        config: { thresholds },
    } = state;
    return {
        thresholds,
    };
};

export default connect(mapStateToProps)(ThresholdsModal);
