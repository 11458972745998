import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import DropdownOptions from 'commons/src/components/dropdown/DropdownOptions';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import DeleteConfirmModal from 'commons/src/components/modals/DeleteConfirmModal';
import { roleRestrictions } from 'commons/src/constants';
import { MenuItem } from 'commons/src/models/menuModels';
import { deleteSpaceFloorPlan } from '../../../actions/floorPlanSpaceActions';
import { SpaceFloorPlan, SpaceFloorPlanMode } from '../../../models/spaceFloorPlanModels';
import { Store } from '../../../reducers';
import EditFloorPlanDetails from '../addFloorPlan/EditFloorPlanDetails';
import styles from './FloorPlanHeader.module.scss';

export type Props = {
    floorPlanId?: string;
    selectedFloorPlan: SpaceFloorPlan;
    locationId: string;
    mode: SpaceFloorPlanMode | undefined;
    setMode: (mode: SpaceFloorPlanMode | undefined) => void;
    saveZone: () => void;
    deleteZone: () => void;
};
const FloorPlanHeader = ({
    floorPlanId,
    mode,
    setMode,
    selectedFloorPlan,
    locationId,
    saveZone,
    deleteZone,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const { loading: deleteLoading, error: deleteError } = useSelector(
        (state: Store) => state.requests.DELETE_SPACE_FLOOR_PLAN
    );

    const { loading: createLoading, error: createError } = useSelector(
        (state: Store) => state.requests.CREATE_SPACE_FLOOR_PLAN_ZONE
    );

    const { loading: editLoading, error: editError } = useSelector(
        (state: Store) => state.requests.EDIT_SPACE_FLOOR_PLAN_ZONE
    );

    const onDeleteConfirm = (): void => {
        dispatch(deleteSpaceFloorPlan(selectedFloorPlan.id, locationId));
    };

    useEffect(() => {
        setIsEditing(false);
    }, [floorPlanId]);

    const onSave = (): void => {
        saveZone();
    };

    useEffect(() => {
        if (!deleteLoading && !deleteError) {
            setMode(undefined);
        }
    }, [deleteLoading]);

    useEffect(() => {
        if (!createLoading && !createError) {
            setMode(undefined);
        }
    }, [createLoading]);

    useEffect(() => {
        if (!editLoading && !editError) {
            setMode(undefined);
        }
    }, [editLoading]);

    useEffect(() => {
        if (!deleteLoading && !deleteError) {
            setDeleteModalOpen(false);
            setMode(undefined);
        }
    }, [deleteLoading, deleteError]);

    const floorDropdownOptions: MenuItem[] = [
        {
            onClick: (): void => setIsEditing(true),
            text: txt('Edit'),
            requiredRoleLevel: roleRestrictions.editSpace,
            disabled: false,
            requiredGroupTypes: [],
        },
        {
            onClick: (): void => setDeleteModalOpen(true),
            text: txt('Delete'),
            requiredRoleLevel: roleRestrictions.editSpace,
            disabled: false,
            requiredGroupTypes: [],
        },
    ];
    return (
        <div className={styles.header}>
            {isEditing ? (
                <EditFloorPlanDetails
                    locationId={locationId}
                    floorPlan={selectedFloorPlan}
                    onClose={(): void => setIsEditing(false)}
                />
            ) : (
                <div className={styles.headerWrapper}>
                    <div className={styles.headerDetails}>
                        <div className={styles.floorNr}>
                            {selectedFloorPlan.floor !== undefined
                                ? txt('SpaceFloorPlan.FloorNr', { floorNr: selectedFloorPlan.floor })
                                : txt('SpaceFloorPlan.NoFloorSet')}
                        </div>
                        <div className={styles.floorName}>{selectedFloorPlan.name}</div>
                        {!mode && (
                            <div className={styles.dropdown}>
                                <DropdownOptions
                                    id={`floor-${selectedFloorPlan?.id}`}
                                    options={floorDropdownOptions}
                                    customButton={<MaterialIcon name="more_horiz" />}
                                    dotMenu
                                    alignRight
                                />
                            </div>
                        )}
                    </div>
                    <div className={styles.headerDetails}>
                        {!mode && (
                            <PrimaryButton
                                color="primary"
                                onClick={(): void => setMode(SpaceFloorPlanMode.CREATE)}
                                title={txt('SpaceFloorPlan.DrawSpace')}
                            />
                        )}
                        {!!mode && [SpaceFloorPlanMode.CREATE, SpaceFloorPlanMode.EDIT_NEW].includes(mode) && (
                            <>
                                {mode === SpaceFloorPlanMode.EDIT_NEW && (
                                    <PrimaryButton
                                        icon={<MaterialIcon name="check" />}
                                        onClick={onSave}
                                        loading={createLoading}
                                        title={txt('Save')}
                                        color="confirmed"
                                    />
                                )}
                                <PrimaryButton
                                    color="secondary"
                                    onClick={(): void => setMode(undefined)}
                                    title={txt('Cancel')}
                                />
                            </>
                        )}
                        {mode === SpaceFloorPlanMode.INSPECT && (
                            <>
                                <PrimaryButton
                                    color="secondary"
                                    onClick={(): void => setMode(SpaceFloorPlanMode.EDIT)}
                                    title={txt('SpaceFloorPlan.EditSpace')}
                                />
                                <PrimaryButton
                                    onClick={deleteZone}
                                    loading={deleteLoading}
                                    color="alert"
                                    title={txt('Delete')}
                                />
                            </>
                        )}
                        {mode === SpaceFloorPlanMode.EDIT && (
                            <>
                                <PrimaryButton
                                    icon={<MaterialIcon name="check" />}
                                    onClick={onSave}
                                    title={txt('Save')}
                                    loading={editLoading}
                                    color="confirmed"
                                />
                                <PrimaryButton
                                    color="secondary"
                                    onClick={(): void => setMode(undefined)}
                                    title={txt('Cancel')}
                                />
                            </>
                        )}
                    </div>
                    {deleteModalOpen && (
                        <DeleteConfirmModal
                            centerBody
                            onSubmit={onDeleteConfirm}
                            onCancel={(): void => setDeleteModalOpen(false)}
                            onSubmitText="Delete"
                            description={txt('SpaceFloorPlan.DeleteFloorPlanDescription', {
                                name: selectedFloorPlan.name,
                            })}
                            onCancelText="Cancel"
                            title="SpaceFloorPlan.DeleteFloorPlan"
                            loading={deleteLoading}
                            error={!!deleteError}
                            errorText={deleteError && `ErrorCodes.${deleteError.error}`}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
export default FloorPlanHeader;
