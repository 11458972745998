import React from 'react';
import QRCodeSVG from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { mapDeviceType, publicQrUrl } from 'commons/src/commonFunctions';
import CheckBox from 'commons/src/components/input/Checkbox';
import { AnyDeviceType, Units } from 'commons/src/models/commonTypeScript';
import { SpaceDevice } from '../../../models/spaceModels';

export type Props = {
    devicesInBuilding: { [serialNumber: string]: SpaceDevice };
    updateCheckedDevices: (serialNumbers: string[]) => void;
    checkedDevices: string[];
    language: string;
    units: Units;
    setPublicDevice: (publiclyAvailable: boolean, serialNumber: string, hasPublicUrl: boolean) => void;
};

export const LocationListComponent = (props: Props): React.ReactElement => {
    const { checkedDevices, devicesInBuilding, language, units, setPublicDevice, updateCheckedDevices } = props;
    const { t: txt } = useTranslation();

    const publicDevices = Object.values(devicesInBuilding)
        .filter(device => {
            const publicDevice = device.publiclyAvailable;
            const publicUrl = device.publicUrlPath;
            if (publicDevice && publicUrl === undefined) {
                setPublicDevice(true, device.serialNumber, false);
            }
            return publicDevice && !!publicUrl;
        })
        .sort((device1, device2) => device1.segmentName.localeCompare(device2.segmentName));

    if (publicDevices.length === 0) {
        return <div />;
    }

    const onCheckAll = (): void => {
        if (publicDevices.length === checkedDevices.length) {
            updateCheckedDevices([]);
        } else {
            const publicDeviceSerialNumbers = publicDevices.map(device => device.serialNumber);
            updateCheckedDevices(publicDeviceSerialNumbers);
        }
    };
    const onCheckDevice = (serialNumber: string): void => {
        let updatedList;
        if (checkedDevices.includes(serialNumber)) {
            updatedList = checkedDevices.filter(device => device !== serialNumber);
        } else updatedList = [...checkedDevices, serialNumber];
        updateCheckedDevices(updatedList);
    };

    const sortedDevices = publicDevices.sort((device1, device2) =>
        device1.segmentName.localeCompare(device2.segmentName)
    );
    return (
        <div className="page-wrapper">
            <div className="public-device__table">
                <div className="public-device__table__row public-device__table__row--header">
                    <CheckBox
                        id="checkAll"
                        label=""
                        checked={publicDevices.length === checkedDevices.length}
                        onChange={onCheckAll}
                        testId="check-all"
                    />
                    <div className="public-device__table__cell public-device__table__cell--name-header">
                        {txt('Device')}
                    </div>
                    <div className="public-device__table__cell">{txt('SerialNumber')}</div>
                    <div className="public-device__table__cell public-device__table__cell--device-type">
                        {txt('DeviceType')}
                    </div>
                </div>
                {sortedDevices.map(device => (
                    <div key={`public-device-${device.serialNumber}`} className="public-device__table__row">
                        <CheckBox
                            id={`public-device-${device.serialNumber}`}
                            label=""
                            checked={checkedDevices.includes(device.serialNumber)}
                            onChange={(): void => onCheckDevice(device.serialNumber)}
                            testId={`check-serialnumber-${device.serialNumber}`}
                        />
                        <div className="public-device__table__cell public-device__table__cell--name">
                            {device.segmentName}
                            <div className="public-device__table__cell--device-details">
                                <div>{device.serialNumber}</div>
                                <div>{txt(`${mapDeviceType(device.deviceType as AnyDeviceType)}FullName`)}</div>
                            </div>
                        </div>
                        <div className="public-device__table__cell">{device.serialNumber}</div>
                        <div className="public-device__table__cell public-device__table__cell--device-type">
                            {txt(mapDeviceType(device.deviceType as AnyDeviceType))}
                        </div>
                        <div style={{ display: 'none' }}>
                            {device.publicUrlPath && (
                                <QRCodeSVG
                                    value={publicQrUrl(device.publicUrlPath, language, units)}
                                    size={102}
                                    id={`qrCode${device.serialNumber}`}
                                />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LocationListComponent;
