import React, { FocusEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'commons/src/components/buttons/FilterButton';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { BuildingType } from 'commons/src/models/commonTypeScript';
import { PresenceInsightFilterType } from '../../../models/common';
import PresenceFilterModal, { notSetOptionName } from './PresenceFilterModal';
import styles from './PresenceInsightFilter.module.scss';

type ParentProps = {
    locationId?: string;
    setSelectedFilters: (filterDetails: { filterType: PresenceInsightFilterType; selectedOptions: string[] }) => void;
    selectedFilters: {
        filterType: PresenceInsightFilterType;
        selectedOptions: string[];
    };
    clearFilterSelection: () => void;
    building: BuildingType;
};

type Props = ParentProps;

const PresenceInsightFilter = ({
    locationId,
    setSelectedFilters,
    selectedFilters,
    clearFilterSelection,
    building,
}: Props): React.ReactElement => {
    const [filterOpen, setFilterOpen] = useState(false);
    const { t: txt } = useTranslation();

    const onOpen = (): void => {
        setFilterOpen(!filterOpen);
    };

    const onBlur = (e: FocusEvent<HTMLElement>): void => {
        const { relatedTarget } = e;
        const filterMenu: Node | null = document.getElementById('filterWrapper');
        if (!relatedTarget || !filterMenu || !filterMenu.contains(relatedTarget)) {
            setFilterOpen(false);
        }
    };

    const selectedFilterNames = selectedFilters.selectedOptions
        .map(option => {
            if (selectedFilters.filterType === PresenceInsightFilterType.floors) {
                return option === notSetOptionName ? txt('FloorTypeOptions.NoFloorSet') : `${txt('Floor')} ${option}`;
            }
            if (selectedFilters.filterType === PresenceInsightFilterType.roomTypes) {
                return txt(option === notSetOptionName ? 'RoomTypeOptions.NoRoomType' : `RoomTypeOptions.${option}`);
            }
            return option;
        })
        .sort();

    const filterText = (selectedFilterNames.length > 2 ? selectedFilterNames.slice(0, 2) : selectedFilterNames).join(
        ', '
    );
    const extraFilterItems = selectedFilterNames.length > 2 ? `+ ${selectedFilterNames.length - 2}` : '';
    const filterTitle = selectedFilterNames.length > 0 ? `${filterText} ${extraFilterItems}` : 'Commons.Filter';

    return (
        <div id="filterWrapper" className={styles.wrapper} onBlur={onBlur}>
            <FilterButton
                icon={<MaterialIcon name="filter_list" />}
                title={filterTitle}
                onClick={onOpen}
                isSelected={false}
                filled
            />
            {filterOpen && (
                <PresenceFilterModal
                    onClose={(): void => setFilterOpen(false)}
                    locationId={locationId}
                    setSelectedFilters={setSelectedFilters}
                    selectedFilters={selectedFilters}
                    onBlur={onBlur}
                    building={building}
                />
            )}
            {selectedFilters.selectedOptions.length > 0 && (
                <TertiaryButton onClick={clearFilterSelection} title="BuildingSensorFocus.ClearSelection" />
            )}
        </div>
    );
};

export default PresenceInsightFilter;
