import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDINGS_REMOVE_FOCUS, BUILDINGS_SELECTED_FOCUS_SENSOR } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';
import { roleRestrictions } from 'commons/src/constants';
import {
    buildingFocusSensorsInAllDevices,
    devicesWithCo2,
    devicesWithPM25,
    devicesWithVoc,
} from 'commons/src/DeviceAndSensorLists';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import { Role, SensorTypes } from 'commons/src/models/commonEnums';
import { AnyDeviceType, DeviceWithKeyInfo, ErrorType } from 'commons/src/models/commonTypeScript';
import { buildingsThresholdsUnsetDefault, setFocusedSensor } from '../../../actions/buildingsTimeOverThresholdActions';
import { SensorBreachThresholds } from '../../../models/common';
import { Store } from '../../../reducers';
import RequestType from '../../../reducers/BusinessRequestType';
import { getThresholdsForSelectedSensor } from '../../buildings/ThresholdInsightRule';
import BuildingFocusedSensorOptions from './BuildingFocusedSensorOptions';
import styles from './BuildingFocusSelector.module.scss';

type StateProps = {
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    loadingFocusedSensor: boolean;
    errorFocusedSensor?: ErrorType;
    focusedSensor?: SensorTypes;
    thresholdOptions: SensorBreachThresholds;
    userRole?: Role;
};

const sensorRequiringDeviceType: { [sensor: string]: AnyDeviceType[] } = {
    // todo is this list correct
    co2: devicesWithCo2,
    voc: devicesWithVoc,
    pm25: devicesWithPM25,
};

export const buildingFocusSensorsInSomeDevices = [SensorTypes.co2, SensorTypes.pm25, SensorTypes.voc];

const BuildingsFocusSelectorComponent = ({
    devicesWithKeyInfo,
    loadingFocusedSensor,
    errorFocusedSensor,
    focusedSensor,
    thresholdOptions,
    userRole,
}: StateProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const requiredUserRoleLevelForEdit =
        userRole && userRoleAboveRequiredLevel(userRole, roleRestrictions.editBuilding);

    const availableSensors = (): SensorTypes[] => {
        const sensorsInBuildingDevices = buildingFocusSensorsInSomeDevices.filter(sensor => {
            const requiredDeviceType = sensorRequiringDeviceType[sensor as keyof typeof sensorRequiringDeviceType];
            return Object.values(devicesWithKeyInfo).find(device => requiredDeviceType.includes(device.type));
        });
        return [...buildingFocusSensorsInAllDevices, ...sensorsInBuildingDevices];
    };

    const sensorThresholds = focusedSensor && thresholdOptions[focusedSensor];
    const { aboveThresholds, belowThresholds } = getThresholdsForSelectedSensor(sensorThresholds);

    const selectFocusSensor = (sensor: SensorTypes): void => {
        analyticsLogger(BUILDINGS_SELECTED_FOCUS_SENSOR, { sensor });
        dispatch(setFocusedSensor(sensor));
    };

    const unsetFocusSensor = (): void => {
        dispatch(buildingsThresholdsUnsetDefault());
        analyticsLogger(BUILDINGS_REMOVE_FOCUS, { clearFocusedSensor: true });
    };

    if (errorFocusedSensor) {
        return (
            <div className={styles.errorWrapper}>
                <ResponseBox text={errorFocusedSensor.error} />
            </div>
        );
    }

    if (focusedSensor && !loadingFocusedSensor && aboveThresholds) {
        return (
            <BuildingFocusedSensorOptions
                selectedSensor={focusedSensor}
                aboveThresholds={aboveThresholds}
                belowThresholds={belowThresholds}
                clearSelection={unsetFocusSensor}
            />
        );
    }
    if (!requiredUserRoleLevelForEdit) {
        return <div />;
    }

    return (
        <div className={styles.focusSelector}>
            <div className={styles.focusDescription}>{txt('BuildingSensorFocus.SelectFocusedSensor')}</div>
            <div className={styles.focusOptions}>
                {availableSensors().map(sensor => (
                    <PrimaryButton
                        color="secondary"
                        key={sensor}
                        title={txt(sensor)}
                        icon={<SensorIcon sensor={sensor} />}
                        onClick={(): void => selectFocusSensor(sensor)}
                        loading={focusedSensor === sensor && loadingFocusedSensor}
                        disabled={loadingFocusedSensor}
                    />
                ))}
            </div>
        </div>
    );
};

const mapsStateToProps = (store: Store): StateProps => {
    const {
        devices: { devicesWithKeyInfo },
        userSettings: { selectedGroup },
        buildingsOverview: { focusedSensor, thresholdOptions },
        requests: {
            [RequestType.BuildingsThresholdsSetFocusedSensor]: {
                loading: loadingFocusedSensor,
                error: errorFocusedSensor,
            },
            [RequestType.GetBuildingsFocusedSensorData]: { error: focusedSensorDataError },
            [RequestType.GetBuildingsThresholdsFocusedSensor]: { error: getDefaultConfigError },
        },
    } = store;
    return {
        devicesWithKeyInfo,
        loadingFocusedSensor,
        errorFocusedSensor: errorFocusedSensor || focusedSensorDataError || getDefaultConfigError,
        focusedSensor,
        thresholdOptions,
        userRole: selectedGroup && selectedGroup.role,
    };
};

export default connect(mapsStateToProps)(BuildingsFocusSelectorComponent);
