import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import PageWrapper from 'commons/src/components/containers/PageWrapper';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import { RequiredRoleLevel, Role } from 'commons/src/models/commonEnums';
import SpacesIllustration from '../../../img/spaces-illustration';
import AddFloorPlan from '../../spaceFloorPlan/addFloorPlan/AddFloorPlan';
import styles from './EmptySpacesAndFloors.module.scss';

type Props = {
    userRole?: Role;
    requiredRoleLevel: RequiredRoleLevel;
    onClick: () => void;
    locationId: string;
};

const EmptySpacesAndFloors = ({ userRole, requiredRoleLevel, onClick, locationId }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const accessToAddDevice: boolean = userRole ? userRoleAboveRequiredLevel(userRole, requiredRoleLevel) : false;

    const [addFloorPlanViewOn, setAddFloorPlanViewOn] = useState<boolean>(false);

    return (
        <PageWrapper pageType="wide">
            <div className={styles.body}>
                <SpacesIllustration />
                <div className={styles.content}>
                    {addFloorPlanViewOn ? (
                        <>
                            <div className={styles.text}>
                                <div className={styles.title}>{txt('AddSpace.AddFloorPlanTitle')}</div>
                                <div className={styles.subTitle}>{txt('AddSpace.AddFloorPlanSubTitle')}</div>
                            </div>
                            <AddFloorPlan locationId={locationId} />
                            <div className={styles.cancelButton}>
                                <TertiaryButton
                                    id="AddSpaceButton"
                                    onClick={(): void => setAddFloorPlanViewOn(false)}
                                    title="Cancel"
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.text}>
                                <div className={styles.title}>{txt('AddSpace.EmptySpacesAndFloorPlansTitle')}</div>
                                <div className={styles.subTitle}>
                                    {txt('AddSpace.EmptySpacesAndFloorPlansSubTitle')}
                                </div>
                            </div>
                            <div className={styles.buttons}>
                                {accessToAddDevice ? (
                                    <>
                                        <PrimaryButton
                                            onClick={(): void => setAddFloorPlanViewOn(true)}
                                            title="SpaceFloorPlan.UploadFloorPlan"
                                            color="primary"
                                        />
                                        <div className={styles.suffix}>{txt('Or')}</div>
                                        <TertiaryButton
                                            id="AddSpaceButton"
                                            onClick={onClick}
                                            title="AddSpace.AddSpace"
                                            noColor
                                            size="medium"
                                            icon={<MaterialIcon name="add" />}
                                        />
                                    </>
                                ) : (
                                    <p className={styles.roleText}>
                                        {txt('Access.RoleDoesNotHaveAccess', { role: txt(userRole || '') })}
                                    </p>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </PageWrapper>
    );
};

export default EmptySpacesAndFloors;
